import { number, object, string } from "yup";
import { get_lang } from "../../../common/utils/app.options";
import { matchPattrens } from "../../../common/utils/app.util";

export const activateNumberFormValidation = object({
  phone_number: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
  email: string().email(get_lang("username_must_be_a_valid_email")).required(get_lang("required")),
});

export const verifyCodeFormValidation = object({
  otp: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
});

export const verifyRedemeFormValidation = object({
  pin: string()
    .matches(/^[0-9]+$/, get_lang("must_be_a_number"))
    .min(10, get_lang("field_10_characters_long"))
    .required(get_lang("required"))
});

export const updateNumerFormValidation = object({
  old_phone_number: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
  new_phone_number: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
});

export const updateEmailFormValidation = object({
  old_email: string().email(get_lang("username_must_be_a_valid_email")).required(get_lang("required")),
  new_email: string().email(get_lang("username_must_be_a_valid_email")).required(get_lang("required")),
});

export const updatePasswordFormValidation = object({
  old_password: string()
    .required(get_lang("no_password_provided")),
  new_password: string()
  .required(get_lang("no_password_provided"))
  .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, get_lang("password_error_message"))
  .min(10, get_lang("password_is_too_short_should_be_10_chars_minimum"))
  .test(
    get_lang("only_these_special_character"),
    get_lang("only_these_special_character"),
    (value) => !matchPattrens(value)
  ),
  confirm_password: string()
    .test("passwords-match", get_lang("passwords_must_match"), function (value) {
      return this.parent.new_password === value;
    }),
});

