import React, { useEffect, useState } from 'react';
import { useFormik } from "formik";
import { verifyCodeFormValidation } from "../validation";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { accountMeAction, activationAction, verifyCodeAction } from "../../../../app-redux/auth/authActions"
import IptvInput from '../../../../common/components/iptv_input';
import {
  selectVarifyCodeStatus,
  selectAuthError,
  resetErrorAction,
  getAccountMe,
} from "../../../../app-redux/auth/authSlice";
import Alert from '../../../../common/components/alerts';
import { get_lang } from '../../../../common/utils/app.options';
import config from '../../../../config';
import { addOrRemoveClassById } from '../../../../common/utils/app.util';

function VerifyCodeForm({ close }) {

  // const countdown = parseInt(process.env.REACT_APP_COUNTDOWN_TIME) || 10;
  const dispatch = useAppDispatch();
  const verifyStatus = useAppSelector(selectVarifyCodeStatus);
  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState("");
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);
  const accountme = useAppSelector(getAccountMe);

  // const [counter, setCounter] = useState(countdown);

  const varifyNumberShow = () => {
    const model = {
      phone_number: accountme.phone_number,
      email: accountme.email,
    };
    dispatch(activationAction(model));
    // setCounter(countdown)
  };
  const formik = useFormik({
    initialValues: {
      phone_number: "",
      email: "",
      otp: ""
    },
    validationSchema: verifyCodeFormValidation,
    onSubmit: (values) => {
      const model = {
        phone_number: accountme.phone_number,
        email: accountme.email,
        otp: values.otp,
      };
      dispatch(verifyCodeAction(model));
    },
  });

  useEffect(() => {
    setIsFormik(true)
  }, [formik.errors]);

  useEffect(() => {
    dispatch(resetErrorAction());
  }, []);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      if (err && err.details && (typeof err.details) === "string") {
        setMessages(null);
        setMessage({ error_msg: err.details, status: error.status });
        setIsFormik(false)
      } else if (err && err.details && (typeof err.details) === "object") {
        setMessages(err.details);
        setMessage(null);
        setIsFormik(false)
      }
    } else {
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    if (verifyStatus && verifyStatus.status === 'success') {
      setMessages(null)
      setMessage({ error_msg: get_lang("account_activated_successfully"), status: verifyStatus.status })
      dispatch(accountMeAction());
      addOrRemoveClassById("form-submit", "disabled");
      setTimeout(() => {
        addOrRemoveClassById("form-submit", "disabled");
        setMessage(null);
        close();
      }, config.ALERT_TIMER);
    }
  }, [verifyStatus]);

  // useEffect(() => {
  //   const timer =
  //     counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  //   return () => clearInterval(timer);
  // }, [counter]);

  return (
    <form id="form-2" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        {message &&
          <Alert title={message.error_msg} type={message.status} />}
      </div>
      <div className="mb-3">
        <label className="form-label">{get_lang("enter_activation_code")}</label>
        <div className='d-flex justify-content-between'>
          <div className="col">
            <IptvInput
              id="otp"
              name="otp"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
              type="text"
            />
          </div>
          <div className="row">
            <a role="button" onClick={varifyNumberShow} className={` w-100`} aria-label="Button">
              {get_lang("resend_otp")}
            </a>
          </div>
        </div>
      </div>
    </form>
  )
}

export default VerifyCodeForm;
