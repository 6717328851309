// /* global UIENV */
// let env = typeof UIENV !== 'undefined' ? UIENV : process.env; // UIENV comes when run through webpack

// let envURL = env.hasOwnProperty('REACT_APP_API_URL') && env.REACT_APP_API_URL !== '' ? env.REACT_APP_API_URL : false;

// Api para Iptv
const API_AUTH_URL =
  process.env.REACT_APP_PALCOPREPAGO_APP_API_AUTH_URL || ""; //staging
const API_BASE_URL =
  process.env.REACT_APP_PALCOPREPAGO_APP_API_BASE_URL || ""; //staging

const LOGGER_URL = process.env.REACT_APP_LOGGER_URL || ""; //staging
const TELECAST_URL = process.env.REACT_APP_TELECAST_URL || ""; //staging
const VISTANA_URL = process.env.REACT_APP_VISTANA_URL || ""; //staging

const LANG = process.env.REACT_APP_LANGUAGE || "en"; //staging
const ALERT_TIMER = parseInt(process.env.REACT_APP_ALERT_TIMER || 5) * 1000;
const MAX_TRIES = process.env.REACT_APP_MAX_TRIES || 5;
const MAX_TRIES_TIMER = process.env.REACT_APP_MAX_TRIES_TIMER || 5;
const googleAppId = "key here";
const facebookAppId = "key here";
let config = {
  sample: API_BASE_URL + "/sample",
  auth_sample: API_AUTH_URL + "/auth_sample",
};

// config.env = env;
config.TELECAST_URL = TELECAST_URL;
config.VISTANA_URL = VISTANA_URL;
config.LANG = LANG;
config.ALERT_TIMER = ALERT_TIMER;
config.MAX_TRIES = MAX_TRIES;
config.MAX_TRIES_TIMER = MAX_TRIES_TIMER;
config.LOGGER_URL = LOGGER_URL;
config.facebookAppId = facebookAppId;
config.googleAppId = googleAppId;

// Api para Iptv
config.API_BASE_URL = API_BASE_URL;
config.API_AUTH_URL = API_AUTH_URL;

export default config;
