import React, { useEffect, useState } from "react";
import { get_lang } from "../../../../common/utils/app.options";
import { getAccountMe } from "../../../../app-redux/auth/authSlice";
import { sendRequest, send_log } from "../../../../common/api/apiClient";
import Alert from "../../../../common/components/alerts";

import Swal from "sweetalert2";

import { useAppSelector } from "../../../../app-redux/hooks";
import config from "../../../../config";
import { swap } from "formik";
function SendCredentials({ parent_plan, pin, handleClose }) {
  const [message, setMessage] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const accountme = useAppSelector(getAccountMe);
  //const EMAIL_SEND_URL = `${config.API_BASE_URL}subscriptions/${pin}/send-email`;

  const [typeExit, setTypeExit] = useState("" | null);
  const [value, setValue] = useState("" | null);

  useEffect(() => {
    setTypeExit("email");
    setValue(accountme.email);
  }, []);

  const onChangeTypeExit = (selectedOption) => {
    const selectedValue = selectedOption.target.value;
    if (selectedValue === "email") setValue(accountme.email);
    if (selectedValue === "sms") setValue(accountme.phone_number);
    setTypeExit(selectedValue);
  };

  const handleClick = async (e) => {
    const EMAIL_SEND_URL = `${config.API_BASE_URL}subscriptions/${pin}/send-email?destiny=${value}`;

    console.log("el dato ha enviar es ", value);
    e.preventDefault();
    setBtnDisabled(true);

    await sendRequest("get", EMAIL_SEND_URL)
      .then((res) => {
        setMessage(res);

        Swal.fire({
          title: "Redireccionando a la pagina principal",
          html: "Se ha enviado las credenciales exitosamente",
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((err) => {
        send_log(value, err.response.data);
      });
    // handleClose();
  };

  return (
    <>
      <form id="credentials">
        {message && (
          <Alert
            title={message.status}
            text={message.data.message}
            type={message.status}
          />
        )}

        <p>
          {get_lang("the_credentials_for_your_plan") + " "}
          <strong> {parent_plan}</strong>
          {" " + "serán enviadas a "}
        </p>

        <div className=" p-2 mb-10">
          <label htmlFor="type" className="form-label">
            {get_lang("send_by")}
          </label>
          <select
            className="form-select"
            id="type"
            aria-label="Default select example"
            onChange={onChangeTypeExit}
          >
            <option value="email">Email</option>
            <option value="sms">SMS</option>
          </select>
        </div>

        <div className="mb-3 text-start">
          <p className="text-center fw-bold">
            {typeExit === "email" ? accountme.email : accountme.phone_number}
          </p>
        </div>

        <button
          type="submit"
          className={`btn btn-primary ${btnDisabled ? "disabled" : ""}`}
          onClick={handleClick}
        >
          {get_lang("send")}
        </button>
      </form>
    </>
  );
}

export default SendCredentials;
