import { createAsyncThunk } from "@reduxjs/toolkit";
import { sendRequest2, sendRequest } from "../../common/api/apiClient";

//////////////////////////////////////////////////////////////////
export const verifyPromotionalPin = createAsyncThunk(
  "auth/verifyPromotionalPin",
  async ({ pin }, { rejectWithValue }) => {
    console.log(pin);
    try {
      const res = await sendRequest(
        "GET",
        "redeem/consult_pin_promotional?pin=" + pin
      );
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleVerifiedPromotionalPin = (state, action) => {
  state.status = "failed";
  console.log(action.payload);
  state.authError = action.payload;
};

export const redeemPromotionalPin = createAsyncThunk(
  "auth/redeemPromotionalPin",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest(
        "POST",
        "redeem/redeem_pin_promotional",
        state
      );
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleRedeemPromotionalPin = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

//////////////////////////////////////////////////////////////////

export const loginAction = createAsyncThunk(
  "auth/loginAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/sign_in", state);
      return res;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleloginFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const logOutAction = createAsyncThunk(
  "auth/logOutAction",
  async ({ rejectWithValue }) => {
    try {
      const res = await sendRequest("GET", "accounts/logout", {
        headers: {
          Authorization: "application/json",
        },
      });
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const verifyEmailAction = createAsyncThunk(
  "auth/verifyEmailAction",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "auth/verify?email=" + email, {
        otp,
      });
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleVerifyEmailFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const registerAction = createAsyncThunk(
  "auth/registerAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/sign_up", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleRegisterFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const forgotPasswordAction = createAsyncThunk(
  "auth/forgotPasswordAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/forgot-password", state, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleForgotPasswordFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const resetPasswordAction = createAsyncThunk(
  "auth/resetPasswordAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/reset-password", state, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleResetPasswordFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const activationAction = createAsyncThunk(
  "auth/activationAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/activate", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleActivationFailed = (state, action) => {
  state.status = "failed";
  state.authError = action && action.payload;
};
export const verifyCodeAction = createAsyncThunk(
  "auth/verifyCodeAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/verified", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const handleVerifiedCodeFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const activateEmailAction = createAsyncThunk(
  "auth/activateEmailAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/activate-email", state);
      console.log(res.data);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleActivateEmailActionFailed = (state, action) => {
  state.status = "failed";
  state.authError = action && action.payload;
};

export const verifyEmailCodeAction = createAsyncThunk(
  "auth/verifyEmailCodeAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "accounts/verified-email", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const handleVerifiedEmailCodeFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const redemePinAction = createAsyncThunk(
  "auth/redemePinAction",
  async (state, { rejectWithValue }) => {
    try {
      const res = await sendRequest("POST", "redeem/", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const handleRedemePinFailed = (state, action) => {
  state.status = "failed";

  state.authError = action.payload;
  var d = new Date();
  var milliseconds = Date.parse(d);
  const {
    access,
    email,
    full_name,
    is_active,
    phone_number,
    refresh,
    redemePinApiCallLimit,
  } = state.data;
  let obj;
  if (redemePinApiCallLimit && redemePinApiCallLimit.tries) {
    let tries = parseInt(redemePinApiCallLimit.tries) + 1;
    obj = { ...redemePinApiCallLimit, tries: tries };
  } else {
    obj = { tries: 1, startTime: milliseconds };
  }
  state.data.redemePinApiCallLimit = obj;
  localStorage.setItem(
    "palco-prepago-auth-user",
    JSON.stringify({
      access,
      email,
      full_name,
      is_active,
      phone_number,
      refresh,
      redemePinApiCallLimit: obj,
    })
  );
};

export const accountMeAction = createAsyncThunk(
  "auth/accountMeAction",
  async (state = "", { rejectWithValue }) => {
    try {
      const res = await sendRequest("GET", "accounts/me", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleAccountMeFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const updateProfileAction = createAsyncThunk(
  "auth/updateProfileAction",
  async (state = "", { rejectWithValue }) => {
    try {
      const res = await sendRequest("PATCH", "accounts/edit/", state);
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const handleUpdateAccountMeActionFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};

export const handleUpdateProfileFailed = (state, action) => {
  state.status = "failed";
  state.authError = action.payload;
};
