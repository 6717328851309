import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { registerFormValidation } from "./validation";
import { registerAction } from "../../../app-redux/auth/authActions";
import {
  resetErrorAction,
  resetRegisterStatus,
  selectAuthError,
  selectRegisterStatus,
  selectStatus,
} from "../../../app-redux/auth/authSlice";
import IptvSelect from "../../../common/components/iptv_select";
import IptvInput from "../../../common/components/iptv_input";
import {
  get_country_options,
  get_gender_options,
  get_lang,
} from "../../../common/utils/app.options";
import Alert from "../../../common/components/alerts";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import CLoader from "../../../common/components/loader";
import config from "../../../config";
import { addOrRemoveClassById } from "../../../common/utils/app.util";
import { get_errors_code_mapper } from "../../../common/utils/app.error";

function Signup() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectAuthError);
  const registerStatus = useAppSelector(selectRegisterStatus);
  const [messages, setMessages] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);
  const [loader, showLoader] = useState(false);
  const authStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (authStatus === "loading") showLoader(true);
    else {
      showLoader(false);
    }
  }, [authStatus]);
  const formik = useFormik({
    initialValues: {
      country: "",
      phone_number: "",
      full_name: "",
      dni: "",
      gender: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: registerFormValidation,
    onSubmit: (values) => {
      const model = {
        country: values.country,
        phone_number: values.phone_number,
        full_name: values.full_name,
        dni: values.dni,
        gender: values.gender,
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
      };
      dispatch(registerAction(model));
    },
    validateOnChange: true,
  });
  useEffect(() => {
    // formik.validateForm();
  }, []);

  useEffect(() => {
    setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    dispatch(resetErrorAction());
    setMessage(null);
    setMessages(null);
  }, []);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      let code = error && error.code;
      let err_str = get_errors_code_mapper(code.toString());
      if (err_str !== "") {
        setMessages(null);
        setMessage({ error_msg: err_str, status: "danger" });
        setIsFormik(false);
      } else {
        if (err && err.details && typeof err.details === "string") {
          setMessages(null);
          setMessage({ error_msg: err.details, status: error.status });
          setIsFormik(false);
        } else if (err && err.details && typeof err.details === "object") {
          let code = error && error.code;
          setErrorCode(code);
          setMessages(err.details);
          setMessage(null);
          setIsFormik(false);
        }
      }
    } else {
      setMessages(null);
      setMessage(null);
    }
  }, [error]);

  useEffect(() => {
    if (registerStatus && registerStatus.status === "success") {
      setMessages(null);
      addOrRemoveClassById("form-submit", "disabled");
      setMessage({
        error_msg: get_lang("user_successfully_registered"),
        status: registerStatus.status,
      });
      setTimeout(() => {
        addOrRemoveClassById("form-submit", "disabled");
        dispatch(resetRegisterStatus());
        setMessage(null);
        navigate("/auth/signin");
      }, config.ALERT_TIMER);
    }
  }, [registerStatus]);

  return (
    <>
      <div className="d-flex flex-column bg-iptv-primary">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="text-center mb-2">
              <a role="button" className="navbar-brand navbar-brand-autodark">
                <img
                  src="https://i0.wp.com/palcoprepago.com/wp-content/uploads/2021/02/Palco-prepago-logo-blanco-ecuador.png?w=419&ssl=1"
                  height={110}
                  alt=""
                />
              </a>
            </div>
            <form
              className="card card-md"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="card-body">
                <h2 className="card-title text-center mb-4">
                  {get_lang("create_an_account")}
                </h2>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    {message && (
                      <Alert title={message.error_msg} type={message.status} />
                    )}
                  </div>
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label"> {get_lang("country")}</label>
                    <IptvSelect
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="select"
                      options={get_country_options()}
                      defualt_option={get_lang("select_a_country")}
                      option_lable="name"
                      option_value="name"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">
                      {" "}
                      {get_lang("mobile_number")}
                    </label>

                    <IptvInput
                      id="phone_number"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages, code: errorCode }
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">{get_lang("name")}</label>
                    <IptvInput
                      id="full_name"
                      name="full_name"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                    />
                    {/* <div className="invalid-feedback d-block">{obj && obj.errors[name]}</div> */}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 col-xl-6 col-sm">
                    <label className="form-label">{get_lang("id")}</label>

                    <IptvInput
                      id="dni"
                      name="dni"
                      value={formik.values.dni}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                    />
                  </div>
                  <div className="col-md-6 col-xl-6 col-sm">
                    <label className="form-label">{get_lang("gender")}</label>
                    <IptvSelect
                      id="gender"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="select"
                      options={get_gender_options()}
                      defualt_option={get_lang("select_a_gender")}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">
                      {get_lang("username_email")}
                    </label>
                    <IptvInput
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(event) => {
                        event.target.value = event.target.value.toLowerCase();
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages, code: errorCode }
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 col-xl-12">
                    <label className="form-label">
                      {" "}
                      {get_lang("password")}{" "}
                    </label>
                    <IptvInput
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="password"
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 col-xl-12">
                    <label className="form-label">
                      {" "}
                      {get_lang("confirm_password")}{" "}
                    </label>
                    <IptvInput
                      id="confirm_password"
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="password"
                    />
                  </div>
                </div>
                <div className="form-footer">
                  <button
                    type="submit"
                    id="form-submit"
                    className="btn btn-primary w-100"
                  >
                    {get_lang("sign_up")}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-white mt-3">
              {get_lang("already_have_an_account")}
              <Link to="/auth/signin" className="link-primary text-light">
                {" "}
                {get_lang("sign_in")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CLoader loader={loader} />
    </>
  );
}

export default Signup;
