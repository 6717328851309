import React, { useEffect, useState } from "react";
export default function Card({ content, setProduct, selectedProduct }) {
  const { nombre, imagen, uuid_product, description } = content;
  const handleChange = () => setProduct(uuid_product);
  return (
    <div className={`row mb-3 `} onClick={handleChange} role="button">
      <div className="col-lg-12">
        <div className={`card`}>
          <div className="card-body">
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-12 col-md-auto">
                <div className="row justify-content-center">
                  <div className="col-auto mr-auto">
                    <label className="form-check text-center">
                      <input
                        className="form-check-input justify-content-center"
                        type="radio"
                        // onChange={() => {}}
                        name="radios"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-11">
                <div className="row justify-content-center align-items-center">
                  <div className="col-sm-8 col-md-8 col-lg-3">
                    <div className="ratio ratio-16x9">
                      <img src={imagen} className="rounded" />
                    </div>
                  </div>
                  <div className="col-sm-10 col-md-11 col-lg-9 text-center text-lg-start text-xl-start">
                    <h3 className="card-title mb-1">
                      <span className="text-reset fw-bold">{nombre}</span>
                    </h3>
                    <div className="text-muted">{description}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
