import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import { useParams } from "react-router";
import { get_lang } from "../../../common/utils/app.options";
import { useNavigate } from "react-router";
import {
  redeemPromotionalPin,
  verifyPromotionalPin,
} from "../../../app-redux/auth/authActions";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";

import {
  selectVerifyPinPromotional,
  resetErrorAction,
} from "../../../app-redux/auth/authSlice";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export default function CuponPopup() {
  const [product, setProduct] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const error = useAppSelector(selectVerifyPinPromotional);
  const [distribuitorName, setDistribuitorName] = useState("");
  const [distribuitorPicture, setDistribuitorPicture] = useState("");
  const [cards, setCards] = useState([]);
  const [pin, setPin] = useState("");
  const [phone, setPhone] = useState("");
  const { coupon } = useParams();
  useEffect(() => {
    const model = {
      pin: coupon,
    };
    dispatch(verifyPromotionalPin(model));
  }, [coupon]);

  useEffect(() => {
    setDistribuitorName(
      JSON.parse(localStorage.getItem("data"))?.distribuitor_name
    );
    setDistribuitorPicture(
      JSON.parse(localStorage.getItem("data"))?.imagen_distribuitor
    );
    setCards(JSON.parse(localStorage.getItem("data"))?.list_products);
    setPin(JSON.parse(localStorage.getItem("data"))?.pin);
    setPhone(
      JSON.parse(localStorage.getItem("palco-prepago-auth-user"))?.phone_number
    );
  }, []);

  useEffect(() => {
    if (localStorage.getItem("data") == null) {
      navigate("/");
      dispatch(resetErrorAction());
    }
    if (error && error.errors) {
      localStorage.removeItem("data");
      navigate("/");
      dispatch(resetErrorAction());
    }
  }, [error]);

  const handleClick = async () => {
    const model = {
      uuid_product: product,
      code: pin,
      phone_number: phone,
      output_type: 'email',
    };
    try {
      const result = await dispatch(redeemPromotionalPin(model));
      localStorage.removeItem("data");
      navigate("/");
      return MySwal.fire({
        title: result.payload?.data?.parent_plan,
        text: result.payload?.data?.sub_plan,
        imageUrl: result.payload?.data?.plan_image,
        imageWidth: 300,
        imageHeight: 200,
        confirmButtonText: "Aceptar",
        imageAlt: result.payload?.data?.parent_plan_identifier,
        customClass: { confirmButton: "btn btn-success" },
        buttonsStyling: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="row justify-content-center m-0 co">
      <div className="card p-3 col-12 col-sm-8 row justify-content-center mt-0 mt-sm-3 mb-3 align-items-center">
        <div className="row justify-content-end px-3 py-2 ">
          <button
            type="button"
            className="btn-close  "
            aria-label="Close"
            onClick={() => navigate("/management/home")}
          ></button>
        </div>
        <div className="col-sm-10 col-md-10 col-lg-6 col-xl-6 row justify-content-center">
          <div className="col-6 mt-3">
            <div className="">
              <img
                src={distribuitorPicture}
                alt={distribuitorName}
                className="rounded"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-3">
          <div className="col-10">
            <h3>
              Muchas gracias por ser un cliente leal de {distribuitorName}.{" "}
            </h3>
          </div>
        </div>
        <div className="row justify-content-center mb-3">
          <div className="col-auto">
            <div className="text-truncate">
              <h3>
                <strong>{get_lang("coupon")}: </strong>
                {pin}{" "}
              </h3>
            </div>
          </div>
          {/* <div className="col-auto">
            <div className="text-truncate">
              <strong>{get_lang("expires")}: </strong> {date}
            </div>
          </div> */}
        </div>
        <div className="row g-3 justify-content-center mt-3 mb-3 text-center">
          <h3 className="h2">{get_lang("select_promotional_plan")}</h3>
        </div>
        {cards ? (
          cards.map((card) => (
            <Card
              content={card}
              setProduct={setProduct}
              selectedProduct={product}
              key={card.uuid_product}
            />
          ))
        ) : (
          <br></br>
        )}
        <div className="row mb-3 justify-content-center">
          <div className="col-auto">
            <button className="btn btn-primary" onClick={handleClick}>
              {get_lang("apply_promotion")}
            </button>
          </div>
        </div>
      </div>
      {/* <IptvModel
        show={showModal}
        showSubmitButton={false}
        handleClose={() => setShowModal(false)}
        component={<Applied />}
        title={get_lang("promotional_cupon")}
        modalSize={"modal-md"}
      /> */}
    </div>
  );
}
