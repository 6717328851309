import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { resetPasswordFormValidation } from "./validation";
import IptvInput from "../../../common/components/iptv_input";
import { resetPasswordAction } from "../../../app-redux/auth/authActions";
import Alert from "../../../common/components/alerts";
import {
  resetErrorAction,
  resetResetPasswordAction,
  selectAuthError,
  selectResetPasswordStatus,
} from "../../../app-redux/auth/authSlice";
import { getCaptcha } from "../../../common/utils/app.captcha";
import "../../../assets/css/iptv-captcha.css";
import { addOrRemoveClassById, getUrlQueryParam } from "../../../common/utils/app.util";
import { get_lang } from "../../../common/utils/app.options";
import config from "../../../config";
import { get_errors_code_mapper } from "../../../common/utils/app.error";

function ResetPassword() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const error = useAppSelector(selectAuthError);

  const [messages, setMessages] = useState("");
  const [message, setMessage] = useState(null);
  const [isFormik, setIsFormik] = useState(false);
  const resetStatus = useAppSelector(selectResetPasswordStatus);

  const formik = useFormik({
    initialValues: {
      // phone_number: "",
      email: "",
      otp: "",
      password: "",
      confirm_password: ""
    },
    validationSchema: resetPasswordFormValidation,
    onSubmit: (values) => {
      const model = {
        // phone_number: values.phone_number,
        email: values.email,
        password: values.password,
        otp: values.otp,
        // captcha: values.captcha,
        // captcha_text: values.captcha_text,
      };
      dispatch(resetPasswordAction(model));
    },
  });

  useEffect(() => {
    dispatch(resetErrorAction());
    dispatch(resetResetPasswordAction());
    // let phone_number = getUrlQueryParam("phone_number");
    // if (phone_number) {
    //   formik.setFieldValue('phone_number', phone_number);
    // }
    let email = getUrlQueryParam("email");
    if (email) {
      formik.setFieldValue('email', email);
    }
    let text = getCaptcha();
    formik.setFieldValue('captcha_text', text);
  }, []);

  useEffect(() => {
    setIsFormik(true)
  }, [formik.errors]);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      let code = error && error.code;
      let err_str = get_errors_code_mapper(code.toString())
      if (err_str !== "") {
        setMessages(null);
        setMessage({ error_msg: err_str, status: "danger" });
        setIsFormik(false)
      } else {
        if (err && err.details && (typeof err.details) === "string") {
          setMessages(null);
          setMessage({ error_msg: err.details, status: error.status });
          setIsFormik(false)
        } else if (err && err.details && (typeof err.details) === "object") {
          setMessages(err.details);
          setMessage(null);
          setIsFormik(false)
        }
      }
    } else {
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    if (resetStatus && resetStatus.status === 'success') {
      setMessages(null)
      addOrRemoveClassById("form-submit", "disabled");
      setMessage({ error_msg: get_lang("password_updated"), status: resetStatus.status })
      setTimeout(() => {
        // dispatch(resetRegisterStatus());
        addOrRemoveClassById("form-submit", "disabled");

        setMessage(null);
        navigate('/auth/signin');
      }, config.ALERT_TIMER);

    }
  }, [resetStatus]);

  // const handleReloadCaptchaClick = () => {
  //   let text = getCaptcha();
  //   formik.setFieldValue('captcha_text', text);
  // };

  return (
    <>
      <div className="d-flex flex-column bg-iptv-primary">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="text-center mb-2">
              <a role="button" className="navbar-brand navbar-brand-autodark">
                <img
                  src="https://i0.wp.com/palcoprepago.com/wp-content/uploads/2021/02/Palco-prepago-logo-blanco-ecuador.png?w=419&ssl=1"
                  height={110}
                  alt=""
                />
              </a>
            </div>
            <form
              className="card card-md"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="card-body">
                <h2 className="card-title text-center mb-4">{get_lang("reset_password")}</h2>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    {message &&
                      <Alert title={message.error_msg} type={message.status} />
                    }
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">{get_lang("username_mobile")}</label>
                    <IptvInput
                      id="phone_number"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">{get_lang("email")}</label>
                    <IptvInput
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                      type="text"
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label">{get_lang("enter_otp_code")}</label>
                  <IptvInput
                    id="otp"
                    name="otp"
                    value={formik.values.otp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                    type="text"
                  />
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 col-xl-12">
                    <label className="form-label">{get_lang("new_password")}</label>
                    <IptvInput
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                      type="password"
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col-md-6 col-xl-12">
                    <label className="form-label"> {get_lang("confirm_password")} </label>
                    <IptvInput
                      id="confirm_password"
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                      type="password"
                    />
                  </div>
                </div>
                <div className="form-footer">
                  <button type="submit" id="form-submit" className="btn btn-primary w-100">
                    {get_lang("reset")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default ResetPassword;
