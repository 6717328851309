import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  getAccountMe,
  resetErrorAction,
  resetRedeemPinStatus,
  selectActivationStatus,
} from "../../../app-redux/auth/authSlice";
import IptvModel from "../../../common/components/model/iptv_model";
import VerifyNumberForm from "./components/activate_number_form";
import VerifyCodeForm from "./components/verify_code";
import IconInfoCircle from "../../../assets/icons/info-circle";
import {
  accountMeAction,
  activateEmailAction,
  activationAction,
} from "../../../app-redux/auth/authActions";
import PlanDetailsForm from "./components/plan_details_form";
import RedemeForm from "./components/redeme";
import { getFormattedDateTime } from "../../../common/utils/app.util";
import { get_lang } from "../../../common/utils/app.options";
import moment from "moment";
import ComingSoon from "./components/coming_soon";
import RedeemCoupon from "./components/RedeemCoupon";

const parseDate = (date, expired) => {
  if (expired) return new Date("01/01/1000");
  let newDate = new Date(date || "09/23/3000");
  return newDate;
};
function Dashboard() {
  const dispatch = useAppDispatch();
  const activationStatus = useAppSelector(selectActivationStatus);
  const accountme = useAppSelector(getAccountMe);
  const [show, setShow] = useState(false);
  const [showRedeemCoupon, setShowRedeemCoupon] = useState(false);

  //Filters

  const [active, setActive] = useState(false);
  const [redemeActive, setRedemeActive] = useState(false);
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const [planDetailsObj, setPlanDetailsObj] = useState(null);
  const [products, setProducts] = useState([]);
  const [showComingSoon, setShowComingSoon] = useState(false);
  useEffect(() => {
    if (accountme.products) {
      let prod = [...accountme.products];
      const today = moment(new Date());
      prod = prod.map((item) => {
        const expiration = moment(item.end_date);
        const product = { ...item };
        const isExpired = expiration.isBefore(today, "day");
        product.status = isExpired ? "expired" : "";
        product.expired = isExpired;
        return product;
      });
      prod = prod.sort(
        (a, b) =>
          parseDate(b.created_at, b.expired) -
          parseDate(a.created_at, a.expired)
      );
      setProducts(prod);
    }
  }, [accountme]);

  const varifyNumberShow = () => {
    const { phone_number } = accountme;
    const model = { phone_number };
    dispatch(activationAction(model));
  };

  const varifyEmail = () => {
    const { email } = accountme;
    const model = { email };
    dispatch(activateEmailAction(model));
  };

  const varifyNumberClose = () => setShow(false);

  const varifyCodeClose = () => {
    setActive(false);
    dispatch(resetRedeemPinStatus());
    dispatch(resetErrorAction());
    dispatch(accountMeAction());
    setRedemeActive(false);
  };

  const closeModalPromotional = () => {
    dispatch(accountMeAction());
    setShowRedeemCoupon(false);
  };

  const redemePop = () => setRedemeActive(true);

  const openPlanDetailsModal = (obj) => {
    setShowPlanDetails(true);
    let parent_plan = obj && obj.parent_plan;
    let sub_plan = obj && obj.sub_plan;
    setPlanDetailsObj({ ...obj, planTitle: parent_plan + " - " + sub_plan });
  };

  useEffect(() => {
    if (activationStatus && activationStatus.status === "success")
      setShow(false);
  }, [activationStatus]);

  const defaultIconProps = {
    className: "icon alert-icon",
    width: 24,
    height: 24,
    stroke: "currentColor",
  };

  return (
    <>
      <div className="page-body">
        <div className="container-md">
          {process.env.REACT_APP_PHONE_VERIFICATION === "true" ? (
            <>
              {accountme.is_active !== true && (
                <div className="alert alert-warning" role="alert">
                  <div className="d-flex">
                    <div>
                      <IconInfoCircle {...defaultIconProps} />
                    </div>
                    <div>
                      <h4 className="alert-title">
                        {get_lang("verify_your_number")}
                      </h4>
                      <div>
                        <div
                          role="button"
                          id="verify-number"
                          onClick={varifyNumberShow}
                          className="link-info"
                        >
                          {get_lang("click_here")}
                        </div>{" "}
                        <div className="">
                          &nbsp{get_lang("to_verify_your_number")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {accountme.is_active !== true && (
                <div className="alert alert-warning" role="alert">
                  <div className="d-flex">
                    <div>
                      <IconInfoCircle {...defaultIconProps} />
                    </div>
                    <div>
                      <h4 className="alert-title">
                        {get_lang("verify_your_email")}
                      </h4>
                      <div className="text-muted">
                        <a
                          role="button"
                          id="verify-email"
                          onClick={varifyEmail}
                          className="text-info"
                        >
                          {get_lang("click_here")}
                        </a>{" "}
                        {get_lang("to_verify_your_email")}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="row justify-content-center mb-2">
            <div className="col-lg-8">
              <div className="card card-md">
                <div className="card-body text-center">
                  <h1 className="mt-2">
                    {get_lang(
                      "please_use_redeme_option_to_activate_new_products"
                    )}
                  </h1>
                </div>
                <div className="hr-text hr-text-center hr-text-spaceless">
                  {get_lang("redeme_your_pin_or_cupon")}
                </div>
                <div className="card-body">
                  <div className="justify-content-center">
                    {/*   <button
                      type="button"
                      onClick={redemePop}
                      id="redeme_pin"
                      className={`btn text-uppercase mb-4 shadow w-100 ${
                        accountme.is_active !== true
                          ? "btn-secondary disabled bg-muted-lt"
                          : "btn-info"
                      }  `}
                    >
                      {get_lang("redeme_your_pin")}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowRedeemCoupon(true)}
                      id="redeme_pin"
                      className={`btn text-uppercase mb-3 w-100 ${
                        accountme.is_active !== true
                          ? "btn-secondary disabled bg-muted-lt"
                          : "btn-info"
                      } `}
                    >
                      {get_lang("promotional_cupon")}
                    </button> */}

                    <button
                      type="button"
                      onClick={redemePop}
                      id="redeme_pin"
                      className={`btn text-uppercase mb-4 shadow w-100 btn-info`}
                    >
                      {get_lang("redeme_your_pin")}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowRedeemCoupon(true)}
                      id="redeme_pin"
                      className={`btn text-uppercase mb-3 w-100 btn-info`}
                    >
                      {get_lang("promotional_cupon")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {accountme && accountme.products && accountme.products.length > 0 && (
            <div className="row">
              <div className="col-lg-12">
                <div className="card ">
                  <div className="card-header">
                    <h3 className="card-title">
                      {get_lang("active_products")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row row-cards row-deck">
                      {products.map((item, i) => {
                        const expiration = item.end_date
                          ? getFormattedDateTime(item.end_date, "YYYY-MM-DD ")
                          : get_lang("pending");
                        return (
                          <div className="col-lg-4 mb-2" key={i}>
                            <div
                              className={`card ${
                                item.status === "expired" ? " text-muted" : ""
                              }`}
                            >
                              <div className="card-body h-100 p-2 ">
                                <h4 className="card-title mb-0">
                                  {item.parent_plan + " - " + item.sub_plan}
                                </h4>
                                <div className="row row-mobile">
                                  <div className="col-md-5 order-12">
                                    <div className="row mt-2">
                                      <p
                                        className="empty-subtitle text-muted"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title={moment(item.end_date).format(
                                          "YYYY-MM-DD, h:mm a"
                                        )}
                                        role="button"
                                      >
                                        {get_lang("exp") + ": " + expiration}
                                      </p>
                                      <p className="empty-subtitle text-muted">
                                        {get_lang("pin") + ": " + item.pin}
                                      </p>
                                      <div className="empty-action align-items-end flex-column mt-2">
                                        {item.status === "expired" ? (
                                          <button
                                            disabled
                                            type="button"
                                            className="btn btn-primary service-account-details"
                                            onClick={() =>
                                              openPlanDetailsModal(item)
                                            }
                                          >
                                            {get_lang("details")}
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btn-primary service-account-details"
                                            onClick={() =>
                                              openPlanDetailsModal(item)
                                            }
                                          >
                                            {get_lang("details")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-7 pt-2 pb-2 pl-2 order-1">
                                    <div className="ratio ratio-16x9">
                                      <img
                                        className={`rounded card-img-top img-fluid ${
                                          item.status === "expired"
                                            ? " opacity-50"
                                            : ""
                                        }  `}
                                        src={
                                          item.plan_image
                                            ? item.plan_image
                                            : "https://cdn.wccftech.com/wp-content/uploads/2020/01/Disney.jpg"
                                        }
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {show && (
          <IptvModel
            show={show}
            button_ok_text={get_lang("submit")}
            button_close_text={get_lang("close")}
            handleClose={varifyNumberClose}
            component={<VerifyNumberForm />}
            form_id="form-1"
            title={get_lang("enter_detail_to_get_activation_code")}
          />
        )}
        {active && (
          <IptvModel
            show={active}
            button_ok_text={get_lang("submit")}
            handleClose={varifyCodeClose}
            component={<VerifyCodeForm close={varifyCodeClose} />}
            form_id="form-2"
            title={`${get_lang("activation_code_sent_to")} ${
              accountme.phone_number
            }`}
          />
        )}
        {redemeActive && (
          <IptvModel
            show={redemeActive}
            button_ok_text={get_lang("submit")}
            handleClose={varifyCodeClose}
            component={<RedemeForm close={varifyCodeClose} />}
            form_id="redeme"
            title={get_lang("redeme_pin")}
          />
        )}
        {showPlanDetails && (
          <IptvModel
            show={showPlanDetails}
            showSubmitButton={false}
            handleClose={() => setShowPlanDetails(false)}
            component={<PlanDetailsForm obj={planDetailsObj} />}
            title={`${get_lang("details")} ${planDetailsObj.planTitle}`}
            modalSize={"modal-lg"}
          />
        )}
        <IptvModel
          show={showComingSoon}
          showSubmitButton={false}
          handleClose={() => setShowComingSoon(false)}
          component={ComingSoon}
          title={get_lang("coming_soon")}
          modalSize={"modal-md"}
        />
        {showRedeemCoupon && (
          <IptvModel
            show
            showSubmitButton={false}
            handleClose={closeModalPromotional}
            component={<RedeemCoupon close={closeModalPromotional} />}
            title={get_lang("promotional_cupon")}
            modalSize={"modal-md"}
          />
        )}
      </div>
    </>
  );
}

export default Dashboard;
