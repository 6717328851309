import { number, object, string } from "yup";
import { get_lang } from "../../../common/utils/app.options";
import { matchPattrens } from "../../../common/utils/app.util";

export const resetPasswordFormValidation = object({
  // phone_number: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
  email: string().email(get_lang("username_must_be_a_valid_email")).required(get_lang("required")),
  otp: number().typeError(get_lang("must_be_a_number")).required(get_lang("required")),
  password: string()
    .required(get_lang("no_password_provided"))
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, get_lang("password_error_message"))
    .min(10, get_lang("password_is_too_short_should_be_10_chars_minimum"))
    .test(
      get_lang("only_these_special_character"),
      get_lang("only_these_special_character"),
      (value) => !matchPattrens(value)
    ),
  confirm_password: string()
    .test("passwords-match", get_lang("passwords_must_match"), function (value) {
      return this.parent.password === value;
    }),
  // captcha: string()
  //   .test("captcha-match", get_lang("captcha_must_match"), function (value) {
  //     let inputVal = value && value.split('').join(' ').trim();
  //     return this.parent.captcha_text.trim() === inputVal;
  //   }),
});

