import { useEffect, useState } from "react";
import DetailForm from "../../../modules/management/dashboard/components/detail_form";
import UpdatePassword from "../../../modules/management/dashboard/components/update_password";
import IptvModel from "../model/iptv_model";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  accountMeAction,
  activateEmailAction,
  logOutAction,
} from "../../../app-redux/auth/authActions";
import UpdateEmailForm from "../../../modules/management/dashboard/components/update_email_form";
import {
  getAccountMe,
  resetErrorAction,
  resetUpdateProfileStatus,
  selectActivateEmailStatus,
  selectStatus,
  deleteAuth,
} from "../../../app-redux/auth/authSlice";
import VerifyEmailCodeForm from "../../../modules/management/dashboard/components/verify-email_code";
import CLoader from "../loader";
import { get_lang } from "../../utils/app.options";
import IconMessageCircle from "../../../assets/icons/message-circle";
import { Link } from "react-router-dom";

const Header = () => {
  const activateEmailStatus = useAppSelector(selectActivateEmailStatus);
  const accountme = useAppSelector(getAccountMe);
  const [showUpdateEmail, setShowUpdateEmail] = useState(false);
  const [detail, setDetail] = useState(false);
  const [updatePass, setUpdatePass] = useState(false);
  const [loader, showLoader] = useState(false);
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (authStatus === "loading") {
      showLoader(true);
    } else {
      showLoader(false);
    }
  }, [authStatus]);
  const varifyEmail = () => {
    const model = {
      email: accountme.email,
    };
    dispatch(activateEmailAction(model));
  };
  const detailFormShow = () => {
    setDetail(true);
  };

  // const updatePassword = () => {
  //   setUpdatePass(true)
  // }

  const handleClose = () => {
    setDetail(false);
    setUpdatePass(false);
    setActive(false);
    dispatch(resetErrorAction());
    dispatch(resetUpdateProfileStatus());
  };

  const handleLogout = async () => {
    dispatch(deleteAuth());
    await dispatch(logOutAction());
  };

  useEffect(() => {
    dispatch(accountMeAction());
  }, []);

  useEffect(() => {
    if (activateEmailStatus && activateEmailStatus.status === "success") {
      setActive(true);
    }
  }, [activateEmailStatus]);

  return (
    <>
      <header className="navbar navbar-expand-md navbar-dark bg-pp-primary d-print-none sticky-top">
        <div className="container-xl">
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <a href=".">
              <img
                src="https://palcoprepago.com/wp-content/uploads/2023/11/logo-white.png"
                width={110}
                height={32}
                alt="Logo"
                className="navbar-brand-image"
              />
            </a>
          </h1>
          <div className="nav-item dropdown">
            <a
              role="button"
              className="nav-link d-flex lh-1 text-reset p-0"
              data-bs-toggle="dropdown"
              aria-label="Open user menu"
            >
              <div className=" d-xl-block ps-2">
                <div>{get_lang("account")}</div>
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
              <Link
                id="account-details"
                className="dropdown-item"
                to="/management/profile"
              >
                {get_lang("profile_account")}
              </Link>
              {/* <a id="change-password" className="dropdown-item" onClick={updatePassword}>{get_lang("update_password")} </a> */}
              <a role="button" id="feedback-form" className="dropdown-item">
                {get_lang("get_help")}
              </a>

              {accountme.is_email_active !== true && (
                <a
                  role="button"
                  id="confirm-email"
                  onClick={varifyEmail}
                  className="dropdown-item"
                >
                  {get_lang("confirmation_email")}
                </a>
              )}
              <div className="dropdown-divider" />
              <a role="button" className="dropdown-item" onClick={handleLogout}>
                {get_lang("logout")}
              </a>
            </div>
          </div>
        </div>
      </header>

      {showUpdateEmail && (
        <IptvModel
          show={showUpdateEmail}
          button_ok_text={get_lang("submit")}
          button_close_text={get_lang("close")}
          handleClose={() => setShowUpdateEmail(false)}
          form_id="form-update-email"
          title={get_lang("update_email")}
          component={<UpdateEmailForm />}
        />
      )}
      {/* {detail &&
        <IptvModel show={detail} button_close_text={get_lang("close")} showSubmitButton={false} handleClose={handleClose} form_id="detail-form" title={get_lang("detail_form")} component={<DetailForm />} modalSize={"modal-lg"} />} */}
      {updatePass && (
        <IptvModel
          show={updatePass}
          button_ok_text={get_lang("submit")}
          button_close_text={get_lang("close")}
          handleClose={handleClose}
          form_id="update-password"
          title={get_lang("update_password")}
          component={<UpdatePassword close={handleClose} />}
        />
      )}
      {active && (

        <IptvModel
          show={active}
          button_ok_text={get_lang("submit")}
          handleClose={handleClose}
          component={<VerifyEmailCodeForm close={handleClose} />}
          form_id="varify-email"
          title={`${get_lang("activation_code_sent_to")} ${accountme.email}`}
        />
      )}
      {loader && <CLoader loader={loader} />}
      <button className="btn btn-primary cfloating-action-button rounded-circle open-zammad-chat text-center">
        <IconMessageCircle size="56" className="m-0 cicon-size" />
      </button>
    </>
  );
};

export default Header;
