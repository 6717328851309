/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import IptvSelect from "../../../common/components/iptv_select";
import {
  get_country_options,
  get_gender_options,
  get_lang,
} from "../../../common/utils/app.options";
import IptvInput from "../../../common/components/iptv_input";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import {
  getAccountMe,
  selectUpdateProfileStatus,
} from "../../../app-redux/auth/authSlice";
import IptvModel from "../../../common/components/model/iptv_model";
import UpdateNumber from "../dashboard/components/update_number";
import UpdateEmail from "../dashboard/components/update_email";
import IconEdit from "../../../assets/icons/edit";
import { updateProfileFormValidation } from "./validation";
import {
  accountMeAction,
  updateProfileAction,
} from "../../../app-redux/auth/authActions";
import config from "../../../config";
import Alert from "../../../common/components/alerts";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

const formatDate = (date) => moment(date).locale("es").format("MMM YYYY");
function UpdateProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const accountme = useAppSelector(getAccountMe);
  const updateProfileStatus = useAppSelector(selectUpdateProfileStatus);
  const [updateNumber, showUpdateNumber] = useState(false);
  const [updateEmail, showUpdateEmail] = useState(false);

  const updateNumberShow = () => {
    showUpdateNumber(true);
  };

  const updateEmailShow = () => {
    showUpdateEmail(true);
  };

  const UpdateNumberClose = () => {
    showUpdateEmail(false);
    showUpdateNumber(false);
  };

  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState(null);
  const [isFormik, setIsFormik] = useState(false);

  const formik = useFormik({
    initialValues: {
      country: "",
      phone_number: "",
      full_name: "",
      dni: "",
      gender: "",
      email: "",
    },
    validationSchema: updateProfileFormValidation,
    onSubmit: (values) => {
      const model = {
        country: values.country,
        full_name: values.full_name,
        dni: values.dni,
        gender: values.gender,
      };
      dispatch(updateProfileAction(model));
    },
  });

  useEffect(() => {
    setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    formik.setFieldValue("country", accountme.country ? accountme.country : "");
    formik.setFieldValue(
      "phone_number",
      accountme.phone_number ? accountme.phone_number : ""
    );
    formik.setFieldValue(
      "full_name",
      accountme.full_name ? accountme.full_name : ""
    );
    formik.setFieldValue("dni", accountme.dni ? accountme.dni : "");
    formik.setFieldValue("gender", accountme.gender ? accountme.gender : "");
    formik.setFieldValue("email", accountme.email ? accountme.email : "");
  }, [accountme]);

  useEffect(() => {
    if (updateProfileStatus && updateProfileStatus.status === "success") {
      dispatch(accountMeAction());
      setMessages(null);
      setMessage({
        error_msg: get_lang("profile_update_success_msg"),
        status: updateProfileStatus.status,
      });
      setTimeout(() => {
        setMessage(null);
      }, config.ALERT_TIMER);
    }
  }, [updateProfileStatus]);

  return (
    <div className="page-body">
      <div className="container-xl">
        <div className="col-md-12 col-lg-12">
          <div className="card">
            <div className="row justify-content-end px-3 py-2 ">
              <button
                type="button"
                className="btn-close  "
                aria-label="Close"
                onClick={() => navigate("/management/home")}
              ></button>
            </div>
            <div className="card-body p-4 row">
              <div className="text-center border border-primary rounded col-md-4 col-lg-4 mb-3">
                <img
                  src={`https://ui-avatars.com/api/?name=${accountme.full_name.replaceAll(
                    " ",
                    "+"
                  )}&size=128`}
                  className="rounded-circle mt-5  mb-3"
                  style={{ width: "230px" }}
                  alt="Avatar"
                />
                {/* <span className="avatar avatar-xl  avatar-rounded  mb-3  " style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1519834785169-98be25ec3f84?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8Y2VsZWJyYXRlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60)' }} /> */}
                <h3>
                  <strong>
                    {accountme.full_name.charAt(0).toUpperCase() +
                      accountme.full_name.slice(1)}
                  </strong>
                </h3>
                <span className="text-muted"> {accountme.email} </span>
                <br />
                <span className="text-muted mt-0">
                  {accountme.phone_number}
                </span>
                <br />
                <br />
                <span className="text-muted ">
                  {`${get_lang("member_since")} ${formatDate(
                    accountme.created_at
                  )}`}
                </span>
              </div>
              <div className="col-lg-7 col-md-7 ms-md-2 ms-lg-5 ">
                {message && (
                  <Alert title={message.error_msg} type={message.status} />
                )}
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="col-md-12 col-lg-10 mb-3">
                    <label className="form-label text-start">
                      {get_lang("name")}
                    </label>
                    <IptvInput
                      id="full_name"
                      name="full_name"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                      disabled="true"
                    />
                  </div>
                  <div className="col-md-12 col-lg-10 mb-3">
                    <div>
                      <label className="form-label text-start">
                        {get_lang("username_email")}
                      </label>
                      <div className="row g-2">
                        <div className="col">
                          <IptvInput
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onChange={(event) => {
                              event.target.value =
                                event.target.value.toLowerCase();
                              formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                            error={
                              isFormik
                                ? { type: "formik", error: formik && formik }
                                : { type: "server", error: messages }
                            }
                            type="text"
                            disabled={true}
                          />
                        </div>
                        {/* {accountme.is_phone_active === true && */}
                        <div className="col-auto">
                          <button
                            type="button"
                            onClick={updateEmailShow}
                            className="btn btn-white btn-icon"
                            disabled="true"
                          >
                            <IconEdit />
                          </button>
                        </div>
                        {/* } */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-10 mb-3">
                    <div>
                      <label className="form-label text-start">
                        {" "}
                        {get_lang("mobile_number")}
                      </label>
                      <div className="row g-2">
                        <div className="col">
                          <IptvInput
                            id="phone_number"
                            name="phone_number"
                            value={formik.values.phone_number}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              isFormik
                                ? { type: "formik", error: formik && formik }
                                : { type: "server", error: messages }
                            }
                            type="text"
                            disabled={true}
                          />
                        </div>
                        {accountme.is_email_active === true && (
                          <div className="col-auto">
                            <button
                              type="button"
                              onClick={updateNumberShow}
                              className="btn btn-white btn-icon"
                              disabled="true"
                            >
                              <IconEdit />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-10 mb-3">
                    <label className="form-label text-start">
                      {get_lang("id")}
                    </label>
                    <IptvInput
                      id="dni"
                      name="dni"
                      value={formik.values.dni}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                      disabled="true"
                    />
                  </div>
                  <div className="col-md-12 col-lg-10 mb-3">
                    <label className="form-label text-start">
                      {get_lang("gender")}
                    </label>
                    <IptvSelect
                      id="gender"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled="true"
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="select"
                      options={get_gender_options()}
                      defualt_option={get_lang("select_a_gender")}
                    />
                  </div>
                  <div className="col-md-12 col-lg-10 mb-3">
                    <label className="form-label text-start">
                      {" "}
                      {get_lang("country")}
                    </label>
                    <IptvSelect
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="select"
                      options={get_country_options()}
                      defualt_option={get_lang("select_a_country")}
                      option_lable="name"
                      option_value="name"
                      disabled={true}
                    />
                  </div>
                  {/* <button
                    type="submit"
                    id="form-submit"
                    className="btn btn-info text-end col-lg-3"
                    disabled="true"
                  >
                    {get_lang("update_profile")}
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {updateNumber && (
        <IptvModel
          show={updateNumber}
          button_ok_text={get_lang("submit")}
          button_close_text={get_lang("close")}
          handleClose={UpdateNumberClose}
          component={<UpdateNumber />}
          form_id="update-number"
          title={get_lang("update_number")}
        />
      )}
      {updateEmail && (
        <IptvModel
          show={updateEmail}
          button_ok_text={get_lang("submit")}
          button_close_text={get_lang("close")}
          handleClose={UpdateNumberClose}
          component={<UpdateEmail />}
          form_id="update-email"
          title={get_lang("update_email")}
        />
      )}
    </div>
  );
}

export default UpdateProfile;
