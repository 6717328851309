import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ROUTES } from "./routes";
import { RouteGuard } from "../modules/auth/components/RouteGuard";
import { useAppSelector } from "../app-redux/hooks";
import { isLogin } from "../app-redux/auth/authSlice";
import NotFound from "../modules/common/pages/NotFound";

const AppRouting = () => {
  const isLoggedIn = useAppSelector(isLogin);

  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route, i) => {
          const { path } = route;
          let fullPath = `${path}/*`;
          return (
            <Route
              key={i}
              path={fullPath}
              element={
                route.guard ? (
                  <RouteGuard path={route.path} redirectPath="/auth">
                    {<route.component />}
                  </RouteGuard>
                ) : (
                  <>
                    {!isLoggedIn ? (
                      <>
                        <route.component />
                      </>
                    ) : (
                      <Navigate replace to="/management" />
                    )}
                  </>
                )
              }
            ></Route>
          );
        })}
        <Route path={"/"} element={<Navigate to="/management" />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouting;
