import React, { useState, useEffect } from "react";
import IconEye from "../../../assets/icons/eye";
import {
  getFormikErrorClass,
  getFormikErrorMessage,
} from "../../utils/app.formik.error";
const IptvInput = ({
  id = "",
  type = "text",
  autoComplete = "off",
  value = "",
  checked = null,
  className = "form-control",
  style = null,
  disabled = false,
  name,
  placeholder,
  error,
  onChange,
  onKeyPress,
  onBlur,
  maxLength,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const getFieldType = (_type) => {
    if (type === "password" && showPassword === true) {
      setFieldType("text");
    } else {
      setFieldType(type);
    }
  };
  useEffect(() => {
    getFieldType(type);
  }, []);
  useEffect(() => {
    if (fieldType) getFieldType(fieldType);
  }, [showPassword]);
  return (
    <>
      <div
        className={
          type == "password"
            ? getFormikErrorClass(
                error && error,
                name,
                "input-group input-group-flat",
                "is-invalid"
              )
            : ""
        }
      >
        {type === "textarea" ? (
          <textarea
            type="text"
            id={id}
            name={name}
            placeholder={placeholder}
            autoComplete={autoComplete}
            className={getFormikErrorClass(
              error && error,
              name,
              className,
              "is-invalid"
            )}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        ) : (
          <input
            type={fieldType}
            id={id}
            name={name}
            value={value}
            placeholder={placeholder}
            autoComplete={autoComplete}
            className={getFormikErrorClass(
              error && error,
              name,
              className,
              "is-invalid"
            )}
            style={style}
            checked={checked}
            onChange={onChange}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            disabled={disabled}
            maxLength={maxLength}
          />
        )}
        {type === "password" && (
          <span
            className={getFormikErrorClass(
              error && error,
              name,
              "input-group-text",
              "c-is-invalid"
            )}
          >
            <span
              onClick={() => setShowPassword((prev) => !prev)}
              className="link-secondary"
              title="Show password"
              data-bs-toggle="tooltip"
              role="button"
            >
              <IconEye
                className="icon"
                width="24"
                height="24"
                stroke="currentColor"
              />
            </span>
          </span>
        )}
      </div>
      {getFormikErrorMessage(error && error, name)}
    </>
  );
};
export default IptvInput;
