import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { updatePasswordFormValidation } from "../validation";
import { getAccountMe, resetErrorAction, selectAuthError, selectUpdateProfileStatus,resetUpdateProfileStatus } from "../../../../app-redux/auth/authSlice";
import IptvInput from '../../../../common/components/iptv_input';
import { updateProfileAction } from '../../../../app-redux/auth/authActions';
import Alert from '../../../../common/components/alerts';
import { get_lang } from '../../../../common/utils/app.options';
import config from '../../../../config';

const InitialValues = {
  old_password: "",
  new_password: "",
  confirm_password: "",
}
function UpdatePassword({ close }) {
  const dispatch = useAppDispatch();
  const updateProfile = useAppSelector(selectUpdateProfileStatus);
  const accountme = useAppSelector(getAccountMe);
  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState(null);
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: updatePasswordFormValidation,
    onSubmit: (values, { resetForm }) => {
      const model = {
        old_password: values.old_password,
        new_password: values.new_password,
      };
      resetForm(InitialValues);
      dispatch(updateProfileAction(model));
    },
  });

  useEffect(() => {
    setIsFormik(true)
  }, [formik.errors]);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      if (err && err.details && (typeof err.details[0]) === "string") {
        setMessages(null);
        setMessage({ error_msg: err.details, status: "danger" });
        setIsFormik(false)
      } else if (err && err.details && (typeof err.details) === "object") {
        setMessages(err.details);
        setMessage(null);
        setIsFormik(false)
      }
    } else {
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    dispatch(resetUpdateProfileStatus());
    dispatch(resetErrorAction());
    setMessages(null);
    setMessage(null);
  }, []);

  useEffect(() => {
    if (updateProfile && updateProfile.status === 'success') {
      setMessages(null);
      setMessage({ error_msg: get_lang("password_updated_successfully"), status: updateProfile.status })
      setTimeout(() => {
        dispatch(resetUpdateProfileStatus());
        setMessage(null);
        close();
        // navigate('/auth/signin');
      }, config.ALERT_TIMER);
    }
  }, [updateProfile]);

  return (
    <form id="update-password" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        {message &&
          <Alert title={message.error_msg} type={message.status} />
        }
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-2">
            <label className="form-label">{get_lang("username_email")}</label>
            <div className="form-control-plaintext">{accountme.email}</div>
          </div>
          <div className="mb-2">
            <label className="form-label">{get_lang("old_password")}</label>
            <IptvInput
              id="old_password"
              name="old_password"
              value={formik.values.old_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
              type="password"
            />
          </div>
          <hr/>
          <div className="mb-2">
            <label className="form-label">{get_lang("new_password")}</label>
            <IptvInput
              id="new_password"
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
              type="password"
            />
          </div>
          <div className="row mb-2">
            <div className="col-md-6 col-xl-12">
              <label className="form-label"> {get_lang("confirm_password")} </label>
              <IptvInput
                id="confirm_password"
                name="confirm_password"
                value={formik.values.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                type="password"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default UpdatePassword;
