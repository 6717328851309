import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { signinFormValidation } from "./validation";
import { Link } from "react-router-dom";
import Alert from "../../../common/components/alerts";
import {
  resetErrorAction,
  selectAuthError,
  selectStatus,
} from "../../../app-redux/auth/authSlice";
import { get_lang } from "../../../common/utils/app.options";
import IptvInput from "../../../common/components/iptv_input";
import { loginAction } from "../../../app-redux/auth/authActions";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import CLoader from "../../../common/components/loader";
import { get_errors_code_mapper } from "../../../common/utils/app.error";

function Signin() {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState("");
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);
  const [loader, showLoader] = useState(false);
  const authStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (authStatus === "loading") {
      showLoader(true);
    } else {
      showLoader(false);
    }
  }, [authStatus]);

  // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: signinFormValidation,
    onSubmit: (values) => {
      const model = {
        username: values.username,
        password: values.password,
      };
      dispatch(loginAction(model)).then((res) => {
        if(res.error){
          
          setMessages("error");
          setMessage({ error_msg: "Los datos ingresados no son válidos.", status: "danger" });
        }
        
      
      });
    },
  });

  useEffect(() => {
    dispatch(resetErrorAction());
    setMessage(null);
    setMessages(null);
  }, []);

  useEffect(() => {
    setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      let code = error && error.code;
      let err_str = get_errors_code_mapper(code.toString());
      if (err_str !== "") {
        
        setMessages(null);
        setMessage({ error_msg: err_str, status: "danger" });
        setIsFormik(false);
      } else {
        if (err && err.details && typeof err.details === "string") {
          setMessages(null);
          setMessage({
            error_msg: err.details,
            status: error.status === "error" ? "danger" : error.status,
          });
          setIsFormik(false);
        } else if (err && err.details && typeof err.details === "object") {
          setMessages(err.details);
          setMessage(null);
          setIsFormik(false);
        }
      }
    } else {
      setMessage(null);
      setMessages(null);
    }
  }, [error]);

  return (
    <>
      <div className="d-flex flex-column bg-iptv-primary">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="text-center mb-2">
              <a role="button" className="navbar-brand navbar-brand-autodark">
                <img
                  src="https://palcoprepago.com/wp-content/uploads/2023/11/logo-white.png"
                  height={110}
                  alt="logo"
                />
              </a>
            </div>
            <form
              className="card card-md"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="card-body">
                <h2 className="card-title text-center mb-4">
                  {get_lang("login_to_your_account")}
                </h2>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">

                    {message && (
                      <Alert title={message.error_msg} type={message.status} />
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">
                      {" "}
                      {get_lang("username_email")}
                    </label>
                    <IptvInput
                      id="username"
                      name="username"
                      value={formik.values.username}
                      onChange={(event) => {
                        event.target.value = event.target.value.toLowerCase();
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6 col-xl-12">
                    <label className="form-label">
                      {get_lang("password")}
                      <span className="form-label-description">
                        <Link to="/auth/forgot-password">
                          {" "}
                          {get_lang("forgot_password")}
                        </Link>
                      </span>
                    </label>
                    <IptvInput
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="password"
                    />
                  </div>
                </div>
                {/* <div className="mb-2"> */}
                {/*   <label className="form-check"> */}
                {/*     <input type="checkbox" className="form-check-input" /> */}
                {/*     <span className="form-check-label"> */}
                {/*       {get_lang("remember_me_on_this_device")} */}
                {/*     </span> */}
                {/*   </label> */}
                {/* </div> */}
                <div className="form-footer">
                  <button type="submit" onClick={() => clearCacheData()} className="btn btn-primary w-100">
                    {get_lang("sign_in")}
                  </button>
                </div>
              </div>
            </form>
            <div className="text-center text-white mt-3">
              {get_lang("dont_have_account_yet")}
              <Link to="/auth/signup" className="link-primary text-white ">
                {" "}
                {get_lang("sign_up")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <CLoader loader={loader} />
    </>
  );
}

export default Signin;
