import { get_lang } from "./app.options";

export const get_errors_code_mapper = (code) => {
  let str = "";
  switch (code) {
    case "400":
      str = get_lang("a_user_with_that_phone_already_exists");
      break;
    case "401":
      str = get_lang("user_or_password_incorrect");
      break;
    case "409":
      str = get_lang("reserved_pin_error");
      break;
    case "403":
      str = get_lang("expired_pin_error");
      break;
    case "406":
      str = get_lang("already_activated_pin_error");
      break;
    case "416":
      str = get_lang("inventory_activated_pin_error");
      break;
    case "404":
      str = get_lang("not_registered_pin_error");
      break;
    // case '400':
    //     str = get_lang("format_pin_error");
    //     break;
    case "423":
      str = get_lang("the_pin_sent_doesnt_belong_to_the_requested_product");
      break;
    case "500":
      str = get_lang("transaction_error");
      break;
    default:
      str = "";
  }
  return str;
};
