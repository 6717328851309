import React, { useState } from 'react';
import { getAccountMe } from '../../../../app-redux/auth/authSlice';
import { useAppSelector } from '../../../../app-redux/hooks';
import IconEdit from '../../../../assets/icons/edit';
import IptvModel from '../../../../common/components/model/iptv_model';
import { get_lang } from '../../../../common/utils/app.options';
import UpdateEmail from './update_email';
import UpdateNumber from './update_number';

function DetailForm() {
  const [updateNumber, showUpdateNumber] = useState(false);
  const [updateEmail, showUpdateEmail] = useState(false);
  const accountme = useAppSelector(getAccountMe);

  const updateNumberShow = () => {
    showUpdateNumber(true)
  };

  const updateEmailShow = () => {
    showUpdateEmail(true)
  };

  const UpdateNumberClose = () => {
    showUpdateEmail(false)
    showUpdateNumber(false)
  };


  return (
    <>
      <form id="detail-form-">
        <div className="row">
          <div className="col-md-12">
            <div className="mb-2">
              <label className="form-label">{get_lang("mobile_number")}</label>
              <div className="form-control-plaintext">
                {accountme.phone_number}
                {accountme.is_email_active === true && (
                  <a  className="change-mobile" onClick={updateNumberShow} role="button">
                    <IconEdit />
                  </a>
                )}
              </div>
            </div>
            <div className="mb-2">
              <label className="form-label">{get_lang("name")}</label>
              <div className="form-control-plaintext">{accountme.full_name}</div>
            </div>
            <div className="mb-2">
              <label className="form-label">{get_lang("id")}</label>
              <div className="form-control-plaintext">{accountme.dni}</div>
            </div>
            <div className="mb-2">
              <label className="form-label">{get_lang("username_email")}</label>
              <div className="form-control-plaintext">
                {accountme.email}
                {/* {accountme.is_phone_active === true && */}
                <a role="button" className="change-email" onClick={updateEmailShow}>
                  <IconEdit />
                </a>
                {/* } */}
              </div>
            </div>
            <div className="mb-2">
              <label className="form-label">{get_lang("country")}</label>
              <div className="form-control-plaintext">{accountme.country ? accountme.country : get_lang("not_available")}</div>
            </div>
          </div>
        </div>
      </form>
      {updateNumber &&
        <IptvModel show={updateNumber} button_ok_text={get_lang("submit")} button_close_text={get_lang("close")} handleClose={UpdateNumberClose} component={<UpdateNumber />} form_id="update-number" title={get_lang("update_number")} />

      }
      {updateEmail &&
        <IptvModel show={updateEmail} button_ok_text={get_lang("submit")} button_close_text={get_lang("close")} handleClose={UpdateNumberClose} component={<UpdateEmail />} form_id="update-email" title={get_lang("update_email")} />

      }
    </>

  )
}

export default DetailForm
