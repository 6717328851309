import gender_options from "../../assets/options/gender_options.json";
import countries from "../../assets/options/countries_required.json";
import EN from '../../assets/lang/EN.json'
import ES from '../../assets/lang/ES.json'
import config from "../../config";

export const get_gender_options = () => {
    let languageType = config.LANG;
    return gender_options[languageType];
}

export const get_country_options = (filter_by_continent) => {
    if (filter_by_continent) {
        return countries.filter((item => item.continent_code === filter_by_continent))
    }
    return countries;
}

export const get_lang = (key) => {
    let str = "";
    let languageType = config.LANG;
    switch (languageType) {
        case 'en':
            str = ES[0][key];
            break;
        case 'es':
            str = ES[0][key];
            break;
        default:
            str = ES[0][key];
    }
    return str;
}