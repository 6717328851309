import React, { useState, useEffect } from 'react';
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { activationAction } from "../../../../app-redux/auth/authActions"
import { activateNumberFormValidation } from "../validation";
import {
  getAccountMe,
  resetErrorAction,
  resetRegisterStatus,
  selectActivationStatus,
  selectAuthError,
} from "../../../../app-redux/auth/authSlice";
import Alert from '../../../../common/components/alerts';
import IptvInput from '../../../../common/components/iptv_input';
import { get_lang } from '../../../../common/utils/app.options';
import config from '../../../../config';

function VerifyNumberForm() {

  const dispatch = useAppDispatch();
  const activationStatus = useAppSelector(selectActivationStatus);
  const accountme = useAppSelector(getAccountMe);
  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState("");
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      phone_number: accountme.phone_number,
      email: accountme.email
    },
    validationSchema: activateNumberFormValidation,
    onSubmit: (values) => {
      const model = {

        phone_number: values.phone_number,
        email: values.email,
      };

      dispatch(activationAction(model));
    },
  });

  useEffect(() => {
    setIsFormik(true)
  }, [formik.errors]);

  useEffect(() => {
    dispatch(resetErrorAction());
  }, []);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      if (err && err.details && (typeof err.details) === "string") {
        setMessages(null);
        setMessage({ error_msg: err.details, status: error.status });
        setIsFormik(false)
      } else if (err && err.details && (typeof err.details) === "object") {
        setMessages(err.details);
        setMessage(null);
        setIsFormik(false)
      }
    } else {
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    if (activationStatus && activationStatus.status === 'success') {
      setMessages(null)
      setMessage({ error_msg: get_lang("activation_code_sent_successfully"), status: activationStatus.status })
      setTimeout(() => {
        dispatch(resetRegisterStatus());
        setMessage(null);
        // navigate('/auth/signin');
      }, config.ALERT_TIMER);
    }
  }, [activationStatus]);

  return (
    <>
      <form id="form-1" onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          {message &&
            <Alert title={message.error_msg} type={message.status} />
          }
        </div>
        <div className="mb-3">
          <label className="form-label">{get_lang("email")}</label>
          <IptvInput
            id="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
            type="text"
          />
        </div>
        <div className="mb-3">
          <label className="form-label">{get_lang("username_mobile")}</label>
          <IptvInput
            id="phone_number"
            name="phone_number"
            value={formik.values.phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
            type="text"
          />
        </div>
      </form>
    </>
  )
}

export default VerifyNumberForm;