import { get_lang } from "../../utils/app.options";

const Footer = () => {

  return (
    <footer className="footer footer-transparent bg-white d-print-none">
      <div className="container-xl">
        <div className="row text-center align-items-center flex-row-reverse justify-content-center">
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                {get_lang("copyright")}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
