import React from 'react';

function CLoader({ loader = true }) {
    return (
        <>
            {loader && <div className={`modal-backdrop  ${loader ? 'show' : ''} t-index`}></div>}
            <div className={`modal modal-blur  fade ${loader ? 'show' : 'hide'} cz-index`} id="modal-cloader" tabIndex={-1} role="dialog" aria-hidden="true" style={{ display: `${loader ? 'block' : 'none'}` }} >
                <div className={`modal-dialog text-center`} role="document">
                    <div className="spinner-border c-spinner-grow" role="status">
                    </div>
                </div>
            </div>
        </>
    )
}

export default CLoader;