import { get_lang } from "../../common/utils/app.options";
import { AuthModule } from "./AuthModule";
import ForgotPassword from "./forgot-password";
import ResetPassword from "./reset-password";
import Signin from "./signin";
import Signup from "./signup";



export const AuthRoute = {
  title: "Auth Module",
  path: "/auth",
  // icon: HomeIcon,
  component: AuthModule,
  subRoutes: [
    { component: Signin, path: "signin", title: get_lang("sign_in") },
    { component: Signup, path: "signup", title: get_lang("sign_up") },
    { component: ForgotPassword, path: "forgot-password", title: get_lang("forgot_password") },
    // { component: PasswordChanged, path: "/passwordchanged", title: "Password Changed" },
     { component: ResetPassword, path: "/reset-password", title: get_lang("reset_password")},
    // { component: VerifyEmail, path: "/verifyemail", pathParam: "/:id", title: "Verify Email" },
  ],
};
