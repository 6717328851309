import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export function ModuleRouter(moduleRoute) {
  const { subRoutes } = moduleRoute;
  const mainPage = subRoutes?.length ? subRoutes[0].path : "";

  return (
    <Routes>
      {subRoutes?.map((route, i) => {
        const { params, path } = route;
        let fullPath = path;

        if (route.params) {
          params.forEach((param) => {
            fullPath = path + `/:${param}`;
          });
        }
        return <Route key={i} path={fullPath} element={<route.component />} />;
      })}

      <Route path={"*"} element={<Navigate to={mainPage} />} />
    </Routes>
  );
}
