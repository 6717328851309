import React, { useEffect, useState } from 'react'
import Alert from '../../../../common/components/alerts';
import IptvInput from '../../../../common/components/iptv_input';
import { useFormik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import { selectAuthError, selectUpdateProfileStatus } from '../../../../app-redux/auth/authSlice';
// import { updateProfileAction } from '../../../../app-redux/auth/authActions';
import { get_lang } from '../../../../common/utils/app.options';
import config from '../../../../config';
import { updateEmailFormValidation } from '../validation';

function UpdateEmail() {

  const dispatch = useAppDispatch();
  const updateProfile = useAppSelector(selectUpdateProfileStatus);
  // const activationStatus = useAppSelector(selectActivationStatus);
  // const accountme = useAppSelector(getAccountMe);
  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState("");
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);

  const formik = useFormik({
    initialValues: {
      old_email: "",
      new_email: ""
    },
     validationSchema: updateEmailFormValidation,
    onSubmit: (values) => {
      const model = {

        old_email: values.old_email,
        new_email: values.new_email,
      };

      // dispatch(updateProfileAction(model));
    },
  });

  useEffect(() => {
    setIsFormik(true)
  }, [formik.errors]);

  // useEffect(() => {
  //   dispatch(resetErrorAction());
  // }, []);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      if (err && err.details && (typeof err.details) === "string") {
        setMessages(null);
        setMessage({ error_msg: err.details, status: error.status });
        setIsFormik(false)
      } else if (err && err.details && (typeof err.details) === "object") {
        setMessages(err.details);
        setMessage(null);
        setIsFormik(false)
      }
    } else {
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    if (updateProfile && updateProfile.status === 'success') {
      setMessages(null)
      setMessage({ error_msg: get_lang("email_update_successfully"), status: updateProfile.status })
      setTimeout(() => {
        // dispatch(resetRegisterStatus());
        setMessage(null);
        // navigate('/auth/signin');
      }, config.ALERT_TIMER);
    }
  }, [updateProfile]);
  return (
    <>
      <form id="update-email" onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          {message &&
            <Alert title={message.error_msg} type={message.status} />
          }
        </div>

        <div className="mb-3">
          <label className="form-label">{get_lang("old_email")}</label>
          <IptvInput
            id="old_email"
            name="old_email"
            value={formik.values.old_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
            type="text"
          />
        </div>

        <div className="mb-3">
          <label className="form-label">{get_lang("confirm_email")}</label>
          <IptvInput
            id="new_email"
            name="new_email"
            value={formik.values.new_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
            type="text"
          />
        </div>
      </form>
    </>
  )
}

export default UpdateEmail