import { configureStore } from '@reduxjs/toolkit'
import userReducer  from '../app-redux/auth/authSlice';
import { loadState } from './localstorage';
import { reduxBatch } from '@manaflair/redux-batch'
import managementReducer from './management/managementSlice';


const reducer = {
  auth: userReducer,
  // common: commonReducer,
  management: managementReducer,
}
const preloadedState  = loadState();


const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  // devTools: process.env.NODE_ENV !== 'production',
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  enhancers: [reduxBatch],
})

export default store;

