import { object, string } from "yup";
import { get_lang } from "../../../common/utils/app.options";

export const forgotPasswordFormValidation = object({
  email: string().email(get_lang("username_must_be_a_valid_email")).required(get_lang("required")),
  captcha: string()
    .test("captcha-match", get_lang("captcha_must_match"), function (value) {
      let inputVal = value && value.split('').join(' ').trim();
      return this.parent.captcha_text.trim() === inputVal;
    }),
});

