import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app-redux/hooks";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { forgotPasswordFormValidation } from "./validation";
import IptvInput from "../../../common/components/iptv_input";
import { forgotPasswordAction } from "../../../app-redux/auth/authActions";
import { get_lang } from "../../../common/utils/app.options";
import {
  resetErrorAction,
  selectAuthError,
  selectForgotPasswordStatus,
  selectStatus,
} from "../../../app-redux/auth/authSlice";
import { getCaptcha } from "../../../common/utils/app.captcha";
import "../../../assets/css/iptv-captcha.css";
import Alert from "../../../common/components/alerts";
import IconRefresh from "../../../assets/icons/refresh";
import CLoader from "../../../common/components/loader";
import config from "../../../config";
import { get_errors_code_mapper } from "../../../common/utils/app.error";
import { addOrRemoveClassById } from "../../../common/utils/app.util";

function ForgotPassword() {
  const dispatch = useAppDispatch();
  const error = useAppSelector(selectAuthError);
  const navigate = useNavigate();
  const forgotStatus = useAppSelector(selectForgotPasswordStatus);
  const [messages, setMessages] = useState("");
  const [message, setMessage] = useState(null);
  const [isFormik, setIsFormik] = useState(false);
  const [params, setparams] = useState(null);
  const [loader, showLoader] = useState(false);
  const authStatus = useAppSelector(selectStatus);

  useEffect(() => {
    if (authStatus === "loading") {
      showLoader(true);
    } else {
      showLoader(false);
    }
  }, [authStatus]);

  const formik = useFormik({
    initialValues: {
      // phone_number: "",
      email: "",
      captcha: "",
      captcha_text: "",
    },
    validationSchema: forgotPasswordFormValidation,
    onSubmit: (values) => {
      const model = {
        // phone_number: values.phone_number,
        email: values.email,
      };
      setparams({
        // phone_number: model.phone_number,
        email: model.email,
      });
      dispatch(forgotPasswordAction(model));
    },
  });

  useEffect(() => {
    dispatch(resetErrorAction());
    let text = getCaptcha();
    formik.setFieldValue("captcha_text", text);
  }, []);

  useEffect(() => {
    setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    if (error && error.errors) {
      let err = error.errors;
      let code = error && error.code;
      let err_str =
        get_lang(error.errors.details.replace(/ /g, "_")) ||
        get_errors_code_mapper(code.toString());

      if (err_str !== "") {
        setMessages(null);
        setMessage({ error_msg: err_str, status: "danger" });
        setIsFormik(false);
      } else {
        if (err && err.details && typeof err.details === "string") {
          setMessages(null);
          setMessage({ error_msg: err.details, status: error.status });
          setIsFormik(false);
        } else if (err && err.details && typeof err.details === "object") {
          setMessages(err.details);
          setMessage(null);
          setIsFormik(false);
        }
      }
    } else {
      setMessage(null);
      setMessages(null);
    }
  }, [error]);

  useEffect(() => {
    if (forgotStatus && forgotStatus.status === "success") {
      setMessages(null);
      addOrRemoveClassById("form-submit", "disabled");
      setMessage({
        error_msg: `${get_lang("otp_sent_to")}: 
       ${params.email}
      `,
        status: forgotStatus.status,
      });
      setTimeout(() => {
        // dispatch(resetRegisterStatus());
        addOrRemoveClassById("form-submit", "disabled");
        setMessage(null);
        navigate(`/auth/reset-password?&email=${params.email}`);
      }, config.ALERT_TIMER);
    }
  }, [forgotStatus]);

  const handleReloadCaptchaClick = () => {
    let text = getCaptcha();
    formik.setFieldValue("captcha_text", text);
  };

  return (
    <>
      <div className="d-flex flex-column bg-iptv-primary">
        <div className="page page-center">
          <div className="container-tight py-4">
            <div className="text-center mb-2">
              <a href="/#" className="navbar-brand navbar-brand-autodark">
                <img
                  src="https://i0.wp.com/palcoprepago.com/wp-content/uploads/2021/02/Palco-prepago-logo-blanco-ecuador.png?w=419&ssl=1"
                  height={110}
                  alt=""
                />
              </a>
            </div>
            <form
              className="card card-md"
              onSubmit={formik.handleSubmit}
              autoComplete="off"
            >
              <div className="card-body">
                <h2 className="card-title text-center mb-4">
                  {" "}
                  {get_lang("forgot_password")}
                </h2>
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    {message && (
                      <Alert title={message.error_msg} type={message.status} />
                    )}
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">{get_lang("mobile_number")}</label>
                    <IptvInput
                      id="phone_number"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={isFormik ? { type: "formik", error: formik && formik } : { type: "server", error: messages }}
                      type="text"
                    />
                  </div>
                </div> */}
                <div className="row mb-3">
                  <div className="col-md-12 col-xl-12">
                    <label className="form-label">
                      {get_lang("username_email")}
                    </label>
                    <IptvInput
                      id="email"
                      name="email"
                      value={formik.values.email}
                      onChange={(event) => {
                        event.target.value = event.target.value.toLowerCase();
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="form-label">Captcha</label>
                  <div className="input-group">
                    <div className="form-control p-0 captcha-area">
                      <div className="captcha-img">
                        <img
                          src="/assets/img/captcha-bg.png"
                          alt="Captch Background"
                        />
                        <span className="captcha">
                          {formik.values.captcha_text}
                        </span>
                      </div>
                    </div>
                    {/* <span className="input-group-text"> */}
                    <button
                      className="btn input-group-text reload-btn"
                      type="button"
                      onClick={handleReloadCaptchaClick}
                    >
                      <IconRefresh style={{ marginRight: "0px" }} />
                    </button>
                    {/* </span> */}
                  </div>
                  <div className="col-md-12 col-xl-12 mt-2">
                    <IptvInput
                      id="captcha"
                      name="captcha"
                      value={formik.values.captcha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        isFormik
                          ? { type: "formik", error: formik && formik }
                          : { type: "server", error: messages }
                      }
                      type="text"
                    />
                  </div>
                </div>
                <div className="form-footer">
                  <button
                    type="submit"
                    id="form-submit"
                    className="btn btn-primary w-100"
                  >
                    {get_lang("submit")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <CLoader loader={loader} />
    </>
  );
}
export default ForgotPassword;
