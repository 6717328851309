import { createSlice } from "@reduxjs/toolkit";
import {
  loginAction,
  registerAction,
  forgotPasswordAction,
  logOutAction,
  handleRegisterFailed,
  handleForgotPasswordFailed,
  handleloginFailed,
  handleActivationFailed,
  activationAction,
  verifyCodeAction,
  handleVerifiedCodeFailed,
  accountMeAction,
  handleAccountMeFailed,
  resetPasswordAction,
  handleResetPasswordFailed,
  updateProfileAction,
  activateEmailAction,
  handleActivateEmailActionFailed,
  verifyEmailCodeAction,
  handleVerifiedEmailCodeFailed,
  redemePinAction,
  handleRedemePinFailed,
  handleUpdateProfileFailed,
  ////////////////////////////////
  verifyPromotionalPin,
  handleVerifiedPromotionalPin,
  redeemPromotionalPin,
  handleRedeemPromotionalPin
} from "./authActions";

const initialState = {
  status: "idle",
  data: {},
  registerResponse: {},
  verifyResponse: {},
  forgotPasswordResponse: {},
  resetPasswordResponse: {},
  activationResponse: {},
  updateProfileResponse: {},
};

const authSlice = createSlice({
  name: "auth",
  //createSlice will infer the state type from the 'initialState'argument
  initialState,
  reducers: {
    deleteAuth: () => ({}),
    resetErrorAction: (state) => {
      delete state.authError;
    },
    resetRegisterStatus: (state) => {
      delete state.registerResponse;
    },
    resetResetPasswordAction: (state) => {
      delete state.resetPasswordResponse;
    },
    resetRedeemPinStatus: (state) => {
      delete state.verifyRedemePinResponse;
    },
    //////////////////////////////////////////////
    resetVerifyPromotionalPin: (state) => {
      delete state.verifyPromotionalPinResponse;
    },
    resetRedeemPromotionalPin: (state) => {
      delete state.redeemPromotionalPinResponse;
    },
    ////////////////////////////////////////////////
    resetActivateEmailStatus: (state) => {
      delete state.data;
    },
    resetUpdateProfileStatus: (state) => {
      delete state.updateProfileResponse;
    },
    resetRedemePinApiCallLimit: (state) => {
      const { access, email, full_name, is_active, phone_number, refresh } =
        state.data;
      delete state.data.redemePinApiCallLimit;
      localStorage.setItem(
        "palco-prepago-auth-user",
        JSON.stringify({
          access,
          email,
          full_name,
          is_active,
          phone_number,
          refresh,
          redemePinApiCallLimit: null,
        })
      );
    },
  },

  extraReducers: (builder) => {
    builder
      //////////////////////////////////////////////////////////////////////////////////////////7
      .addCase(verifyPromotionalPin.fulfilled, (state, action) => {
        state.status = "idle";
        state.verifyPromotionalPinResponse = action.payload;
      })
      .addCase(verifyPromotionalPin.rejected, handleVerifiedPromotionalPin)

      .addCase(verifyPromotionalPin.pending, (state) => {
        state.status = "loading";
      })


      .addCase(redeemPromotionalPin.fulfilled, (state, action) => {
        state.status = "idle";
        state.redeemPromotionalPinResponse = action.payload;
      })
      .addCase(redeemPromotionalPin.rejected, handleRedeemPromotionalPin)

      .addCase(redeemPromotionalPin.pending, (state) => {
        state.status = "loading";
      })


      ///////////////////////////////////////////////////////////////////////////////////////////

      .addCase(loginAction.fulfilled, (state, action) => {
        console.log(action.payload)
        state.status = "idle";
        localStorage.setItem(
          "palco-prepago-auth-user",
          JSON.stringify(action.payload.data)
        );
        state.data = action.payload.data;
        window.location.reload();
      })
      .addCase(loginAction.rejected, handleloginFailed)

      .addCase(loginAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(logOutAction.fulfilled, (state) => {
        state.status = "idle";
        localStorage.removeItem("palco-prepago-auth-user");
        window.location.reload();
      })
      .addCase(logOutAction.rejected, (state) => {
        state.status = "failed";
        localStorage.removeItem("palco-prepago-auth-user");
        window.location.reload();
      })
      .addCase(logOutAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(registerAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.registerResponse = action.payload;
      })
      .addCase(registerAction.rejected, handleRegisterFailed)

      .addCase(registerAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.forgotPasswordResponse = action.payload;
      })
      .addCase(forgotPasswordAction.rejected, handleForgotPasswordFailed)

      .addCase(forgotPasswordAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.resetPasswordResponse = action.payload;
      })
      .addCase(resetPasswordAction.rejected, handleResetPasswordFailed)

      .addCase(resetPasswordAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(activationAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.activationResponse = action.payload;
      })
      .addCase(activationAction.rejected, handleActivationFailed)

      .addCase(activationAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(verifyCodeAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.verifyCodeResponse = action.payload;
      })
      .addCase(verifyCodeAction.rejected, handleVerifiedCodeFailed)

      .addCase(verifyCodeAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(activateEmailAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.activateEmailResponse = action.payload;
      })
      .addCase(activateEmailAction.rejected, handleActivateEmailActionFailed)

      .addCase(activateEmailAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(verifyEmailCodeAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.verifyEmailCodeResponse = action.payload;
      })
      .addCase(verifyEmailCodeAction.rejected, handleVerifiedEmailCodeFailed)

      .addCase(verifyEmailCodeAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(redemePinAction.fulfilled, (state, action) => {
        state.status = "idle";
        state.verifyRedemePinResponse = action.payload;
        resetRedemePinApiCallLimit();
      })
      .addCase(redemePinAction.rejected, handleRedemePinFailed)

      .addCase(redemePinAction.pending, (state) => {
        state.status = "loading";
      })

      // .addCase(redemePinApiCallLimitAction.fulfilled, (state) => {
      //   state.status = "idle";
      //   delete state.data;
      //   localStorage.removeItem('palco-prepago-auth-user');
      //   window.location.reload();
      // })
      // .addCase(redemePinApiCallLimitAction.rejected, (state) => {
      //   state.status = "failed";
      //   localStorage.removeItem('palco-prepago-auth-user');
      //   window.location.reload();
      // })
      // .addCase(redemePinApiCallLimitAction.pending, (state) => {
      //   state.status = "loading";
      // })

      .addCase(accountMeAction.fulfilled, (state, action) => {
        state.status = "idle";
        const { is_email_active, is_active } = action.payload.data;
        state.data = {
          ...state.data,
          ...action.payload.data,
          is_active: is_email_active ? true : is_active,
        };
      })
      .addCase(accountMeAction.rejected, handleAccountMeFailed)

      .addCase(accountMeAction.pending, (state) => {
        state.status = "loading";
      })

      .addCase(updateProfileAction.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload);
        state.updateProfileResponse = action.payload;
      })
      .addCase(updateProfileAction.rejected, handleUpdateProfileFailed)

      .addCase(updateProfileAction.pending, (state) => {
        state.status = "loading";
      });
  },
});

export const {
  deleteAuth,
  resetErrorAction,
  resetRegisterStatus,
  resetResetPasswordAction,
  resetRedeemPinStatus,
  resetActivateEmailStatus,
  resetRedemePinApiCallLimit,
  resetUpdateProfileStatus,
  resetVerifyPromotionalPin,
  resetRedeemPromotionalPin
} = authSlice.actions;
//other code such as selector can be implemented 'RootState' type
export default authSlice.reducer;
export const selectStatus = (state) => state.auth.status;
export const selectResetEmail = (state) =>
  state.auth.data && state.auth.data.user && state.auth.data.user.email;
export const selectAuthError = (state) => state.auth.authError;
export const selectRegisterStatus = (state) => state.auth.registerResponse;
export const selectForgotPasswordStatus = (state) =>
  state.auth.forgotPasswordResponse;
export const selectResetPasswordStatus = (state) =>
  state.auth.resetPasswordResponse;
export const selectActivationStatus = (state) => state.auth.activationResponse;
export const selectActivateEmailStatus = (state) =>
  state.auth.activateEmailResponse;
export const selectVarifyCodeStatus = (state) => state.auth.verifyCodeResponse;
export const selectVarifyEmailCodeStatus = (state) =>
  state.auth.verifyEmailCodeResponse;
export const selectRedemePinStatus = (state) =>
  state.auth.verifyRedemePinResponse;
export const selectLoginStatus = (state) => state.auth.data;
export const getAccountMe = (state) => state.auth.data;
export const selectUpdateProfileStatus = (state) =>
  state.auth.updateProfileResponse;
export const selectUser = (state) => state.auth.data.access;
export const selectRedemePinApiCallLimit = (state) =>
  state.auth.data.redemePinApiCallLimit;

export const selectVerifyPinPromotional = (state) =>
  state.auth.authError;

  export const selectRedeemPromotionalPin = (state) =>
  state.auth.authError;

export const isLogin = (state) => {
  return Boolean(state.auth.data.access);
};
