import React, { useEffect, useState } from "react";
import { get_lang } from "../../../../common/utils/app.options";
import IptvInput from "../../../../common/components/iptv_input";
import Alert from "../../../../common/components/alerts";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import data from "../../coupon/sample.json";
import {
  redeemPromotionalPin,
  verifyPromotionalPin,
} from "../../../../app-redux/auth/authActions";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
  resetErrorAction,
  selectVerifyPinPromotional,
} from "../../../../app-redux/auth/authSlice";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const values = {
  errors: null,
  data: {
    is_valid: true,
    list_products: [
      {
        uuid_product: "e0bd0884-9480-476d-8db5-b1400fd8d42d",
        nombre: "MAGISTV",
        description: "1 Mes",
        imagen: null,
      },
    ],
    imagen_distribuitor: null,
    distribuitor_name: "BROADNET L.A",
    pin: "9641077457",
  },
  status: "success",
  code: 200,
};
export default function RedeemCoupon({ close }) {
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState({});
  const error = useAppSelector(selectVerifyPinPromotional);
  const [isFormik, setIsFormik] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const model = {
      pin: formik.values.pin,
    };

    try {
      const result = await dispatch(verifyPromotionalPin(model));
      const isAvailable =
        result.payload?.errors?.is_valid == false ? false : true;
      if (isAvailable) {
        if (result.payload?.data?.list_products.length > 0) {
          if (result.payload?.data?.list_products.length == 1) {
            const model = {
              uuid_product:
                result.payload?.data?.list_products[0]?.uuid_product,
              code: formik.values.pin,
              phone_number: JSON.parse(
                localStorage.getItem("palco-prepago-auth-user")
              )?.phone_number,
              output_type: 'email',
            };
            const req = await dispatch(redeemPromotionalPin(model));

            close();
            return MySwal.fire({
              title: req.payload?.data?.parent_plan,
              text: req.payload?.data?.sub_plan,
              imageUrl: req.payload?.data?.plan_image,
              imageWidth: 300,
              imageHeight: 200,
              confirmButtonText: "Aceptar",
              imageAlt: req.payload?.data?.parent_plan_identifier,
              customClass: { confirmButton: "btn btn-success" },
              buttonsStyling: false,
            });
          } else {
            localStorage.setItem("data", JSON.stringify(result.payload?.data));
            navigate(COUPON_ROUTE);
          }
        } else {
          setMessage({
            error_msg: "500",
            text: "No existe un producto asignado",
            status: "danger",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const validateCoupon = ({ pin }) => {
    const lengthMatch = pin.length === 10;
    let errors = {};
    if (!lengthMatch) errors.pin = get_lang("pin_too_short"); // setMessages(error);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      pin: "",
    },
    onSubmit: handleSubmit,
    validate: validateCoupon,
    validateOnChange: true,
  });

  const COUPON_ROUTE = `/management/coupon/${formik.values.pin}`;

  useEffect(() => {
    setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    //console.log(error);
    if (error && error.errors) {
      setMessages(null);
      setMessage({
        error_msg: error.status,
        text: error.errors.message,
        status: "danger",
      });
      setIsFormik(false);
      dispatch(resetErrorAction());
    }
  }, [error]);

  return (
    <form id="redeme" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        {message && (
          <Alert
            title={message.error_msg}
            text={message.text}
            type={message.status}
          />
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">
          {get_lang("enter_activation_code")}
        </label>

        <div className="d-flex justify-content-between">
          <div className="col">
            <IptvInput
              id="pin"
              name="pin"
              value={formik.values.pin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                isFormik
                  ? { type: "formik", error: formik && formik }
                  : { type: "server", error: messages }
              }
              type="text"
              maxLength={10}
            />
          </div>
        </div>
      </div>
      <button className="btn btn-primary" type="submit">
        {get_lang("submit")}
      </button>
    </form>
  );
}
