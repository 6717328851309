import axios from "axios";
import config from "../../config";

//Instancia 1 Iptv
const ax = axios.create({
  //https://iptvadmin.recobsa.com/api/accounts/activate-email
  //baseURL: config.API_BASE_URL,
  //baseURL: 'https://cliente.palcoprepago.com/api/',
  //baseURL: 'https://iptv-dev-v2.recobsa.com/api/',
  //baseURL: "http://192.168.100.2:48010/api/",
  baseURL: "https://iptvadmin.recobsa.com/api/",
  //https://iptvadmin.recobsa.com/
  withCredentials: true,
});

const logger_url = config.LOGGER_URL;
const browser = window.navigator.userAgent;

console.log("logger_url", browser);
const getUser = () =>
  JSON.parse(localStorage.getItem("palco-prepago-auth-user"));

export const send_log = async (user, description) =>
  await axios.post(logger_url, { user, browser, description });

export async function sendRequest(requestType, endPoint, payload) {
 // console.log("sendRequest", endPoint);
  try {
    let res;
    switch (requestType) {
      case "GET":
        res = await ax.get(endPoint);
        break;
      case "POST":
        res = await ax.post(endPoint, payload);
        break;
      case "PATCH":
        res = await ax.patch(endPoint, payload);
        break;
      case "PUT":
        res = await ax.put(endPoint, payload);
        break;
      case "DELETE":
        res = await ax.delete(endPoint);
        break;
      default:
        res = await ax.get(endPoint);
        break;
    }
    if (res && res.status >= 200 && res.status < 300 && res.data.status) {
      return res.data;
    } else {
    }
    // throw new Error(res.data.error || res.statusText);
  } catch (error) {
    throw error;
  }
}

ax.interceptors.request.use(
  function (config) {
    const data = JSON.parse(localStorage.getItem("palco-prepago-auth-user"));
   // console.log("aqui esta funcionando");
   // console.log(config);
    if (data && data.access) {
      // if(config.url !== "/accounts/logout"){
      config.headers.Authorization = `Bearer ${data.access}`;
      // }
    } else {
      config.headers = {
        Accept: "application/json",
      };
    }

    // Do something before request is sent
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);


// Add a response interceptor
ax.interceptors.response.use(
  
  function (response) {
    
    console.log("sesion iniciada de forma correcta"); 
    // if (response.status === 401)
    //   window.location.href = "/auth/login"

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    console.log("Error al realizar petición")
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
   // console.log(error)
    if (error && error.response) {
      if (error.response.status === 401) {
        // error.data.json.then((res) => console.log(res));
        //window.location.href = "/auth/login"
        const description = error.response.data.errors.details;
        const logged_in_user = getUser();
        const errorUser = JSON.parse(
          error.config.data || '{ "username": "Session expired" }'
        );
        const user_trying_to_login = { email: errorUser.username };
        // error message
        const email = logged_in_user
          ? logged_in_user.email
          : user_trying_to_login.email;
        const access = logged_in_user ? logged_in_user.access : "";
        const refresh = logged_in_user ? logged_in_user.refresh : "";
        const userDataToPost = `${email}, ACCESS TOKEN: ${access}, REFRESH TOKEN: ${refresh}`;
        
       // console.log("description:",description);
        localStorage.removeItem("palco-prepago-auth-user");
       // console.log("logged in user:",logged_in_user)
        if (logged_in_user) window.location.reload();


        await send_log(userDataToPost, description);

        // = "/auth/login"
      }
      return Promise.reject(error);
    }
  }
);

// const lastAuthUser = localStorage.getItem(
//   `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
// );
// const idToken = localStorage.getItem(
//   `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID}.${lastAuthUser}.idToken`
// );
// ax.defaults.headers.common.Authorization = idToken;
