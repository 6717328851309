import React from 'react'
import { getFormikErrorClass, getFormikErrorMessage } from '../../utils/app.formik.error';

function IptvSelect({
  id = "",
  type = "select",
  autoComplete = "off",
  value = "",
  className = "form-select",
  options = [],
  defualt_option = "",
  option_lable = 'lable',
  option_value = "value",
  disabled = false,
  name,
  onChange,
  onBlur,
  error }) {

  return (
    <>
      <select
        type={type}
        id={id}
        name={name}
        autoComplete={autoComplete}
        className={getFormikErrorClass(
          error && error,
          name,
          className,
          "is-invalid"
        )}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        {defualt_option && <option value="">{defualt_option}</option>}
        {options.map((item, k) =>
          <option key={k} value={item[option_value]}>{item[option_lable]}</option>
        )}
      </select>
      {getFormikErrorMessage(error && error, name)}
    </>
  )
}

export default IptvSelect;