import Dashboard from "./dashboard";
import Coupons from "./coupon";
import { ManagementModule } from "./ManagementModule";
import UpdateProfile from "./update-profile";

export const ManagementRoute = {
  title: "Users",
  path: "/management",
  // icon: SettingsIcon,
  component: ManagementModule,
  guard: true,
  subRoutes: [
    { component: Dashboard, path: "home", title: "Home" },
    { component: UpdateProfile, path: "profile", title: "Profile" },
    { component: Coupons, path: "coupon", title: "Coupon", params: ["coupon"] },
    //{ component: Coupons, path: "coupon", title: "Coupon" }
  ],
};
