import { number, object, string } from "yup";
import { get_lang } from "../../../common/utils/app.options";
import { matchPattrens } from "../../../common/utils/app.util";

// const matchPattrens = (string) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);

export const registerFormValidation = object({
  country: string().required(get_lang("required")),
  phone_number: number()
    .typeError(get_lang("must_be_a_number"))
    .required(get_lang("required")),
  full_name: string()
    .required(get_lang("required"))
    .matches(
      /[A-Za-z]+(\s[A-Za-z]+)/,
      get_lang("please_provide_name_and_lastname")
    ),
  dni: string().required(get_lang("required")),
  gender: string().required(get_lang("required")),
  email: string()
    .email(get_lang("username_must_be_a_valid_email"))
    .required(get_lang("required")),

  password: string()
    .required(get_lang("no_password_provided"))
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      get_lang("password_error_message")
    )
    // .matches(/^(?=.*[!@#$%^&*])/, get_lang("only_these_special_character"))
    .min(10, get_lang("password_is_too_short_should_be_10_chars_minimum"))
    .test(
      get_lang("only_these_special_character"),
      get_lang("only_these_special_character"),
      (value) => !matchPattrens(value)
    ),
  confirm_password: string().test(
    "passwords-match",
    get_lang("passwords_must_match"),
    function (value) {
      return this.parent.password === value;
    }
  ),
});
