import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { verifyRedemeFormValidation } from "../validation";
import { useAppDispatch, useAppSelector } from "../../../../app-redux/hooks";
import {
  accountMeAction,
  redemePinAction,
} from "../../../../app-redux/auth/authActions";
import IptvInput from "../../../../common/components/iptv_input";
import {
  selectAuthError,
  resetErrorAction,
  getAccountMe,
  selectRedemePinStatus,
  resetRedeemPinStatus,
  selectRedemePinApiCallLimit,
  resetRedemePinApiCallLimit,
} from "../../../../app-redux/auth/authSlice";
import Alert from "../../../../common/components/alerts";
import { get_lang } from "../../../../common/utils/app.options";
import config from "../../../../config";
import { get_errors_code_mapper } from "../../../../common/utils/app.error";
import { addOrRemoveClassById } from "../../../../common/utils/app.util";

function RedemeForm({ close }) {
  const dispatch = useAppDispatch();
  const redemePinStatus = useAppSelector(selectRedemePinStatus);
  const redemePinApiCallLimit = useAppSelector(selectRedemePinApiCallLimit);

  const error = useAppSelector(selectAuthError);
  const [messages, setMessages] = useState(null);
  const [isFormik, setIsFormik] = useState(false);
  const [message, setMessage] = useState(null);

  const accountme = useAppSelector(getAccountMe);
  const handleSubmit = (values) => {
    const model = {
      phone_number: accountme.phone_number,
      pin: values.pin,
      output_type: 'email'
    };
    if (
      redemePinApiCallLimit &&
      redemePinApiCallLimit.tries >= config.MAX_TRIES
    ) {
      const startTime =
        redemePinApiCallLimit && redemePinApiCallLimit.startTime;
      const currentTime = Date.parse(new Date());
      const totalTime = currentTime - startTime;
      const timer = config.MAX_TRIES_TIMER * 60000;
      const timeout = timer - totalTime;
      const formatTimeout = parseInt(timeout / 60000) + 1;
      const element = document.getElementById("form-submit");
      const max_tries = get_lang(
        "maximum_tries_reached_please_try_again_after"
      );
      const minutes = get_lang("minutes");
      const error_msg = `${max_tries} ${formatTimeout} ${minutes}`;
      if (totalTime < timer) {
        setMessage({ error_msg, status: "danger" });
        if (element) element.classList.add("disabled");
        setTimeout(() => {
          if (element) element.classList.remove("disabled");
          setMessage(null);
          dispatch(resetErrorAction());
        }, timeout);
      } else {
        dispatch(resetRedemePinApiCallLimit());
        dispatch(redemePinAction(model));
      }
    } else {
      setMessage(null);
      dispatch(resetErrorAction());
      dispatch(redemePinAction(model));
    }
  };

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      pin: "",
    },
    validationSchema: verifyRedemeFormValidation,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (formik.errors !== {}) setIsFormik(true);
  }, [formik.errors]);

  useEffect(() => {
    dispatch(resetErrorAction());
    dispatch(resetRedeemPinStatus());
    setMessages(null);
    setMessage(null);
  }, []);

  useEffect(() => {
    if (error && error.errors) {
      console.log(error);
      let err = error.errors;
      let code = err.details && err.details.error && err.details.error.code;
      let err_str = get_errors_code_mapper(code);
      if (err_str !== "") {
        setMessages(null);
        setMessage({ error_msg: err_str, status: "danger" });
        setIsFormik(false);
      } else {
        if (err && err.details) {
          const { details } = err;
          if (typeof details === "string") {
            setMessages(null);
            setMessage({ error_msg: details, status: error.status });
            setIsFormik(false);
          } else if (typeof details === "object") {
            setMessages(details);
            setMessage(null);
            setIsFormik(false);
          }
        }
      }
    }
    // else {
    //   // setMessages(null);
    //   setMessage(null);
    // }
  }, [error]);

  useEffect(() => {
    if (redemePinStatus) {
      const { status } = redemePinStatus;
      if (status === "success") {
        setMessages(null);
        setMessage({
          error_msg: get_lang("pin_redeemed_successfully"),
          text: get_lang(
            "please_check_your_email_to_view_your_platform_credentials"
          ),
          status,
        });
        dispatch(accountMeAction());
        addOrRemoveClassById("form-submit", "disabled");
        setTimeout(() => {
          addOrRemoveClassById("form-submit", "disabled");
          dispatch(resetRedeemPinStatus());
          dispatch(resetErrorAction());
          setMessage(null);
          close();
        }, config.ALERT_TIMER);
      }
    }
  }, [redemePinStatus]);

  return (
    <form id="redeme" onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        {message && (
          <Alert
            title={message.error_msg}
            text={message.text}
            type={message.status}
          />
        )}
      </div>
      <div className="mb-3">
        <label className="form-label">
          {get_lang("enter_activation_code")}
        </label>

        <div className="d-flex justify-content-between">
          <div className="col">
            <IptvInput
              id="pin"
              name="pin"
              value={formik.values.pin}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                isFormik
                  ? { type: "formik", error: formik && formik }
                  : { type: "server", error: messages }
              }
              type="text"
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default RedemeForm;
