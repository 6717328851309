import moment from "moment";

export function add(x, y) {
  return x + y;
}

export const matchPattrens = (string) => /[()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(string);

export const getFormattedDateTime = (date = "", format = "DD-MM-YYYY") => {
  // return moment(date && date ? date : null).format(format);
  return date ? moment(date).format(format) : "";
}

export const setDateTimeFormat = (date = "", format = "DD-MM-YYYY") => {
  return moment(date, format).toDate();
}

export const addToDate = (type = "days", value = 0) => {
  const today = moment().toDate();
  const finalDate = moment(moment(today).add(value, type));
  return finalDate;
}

export const scrollToTop = (top = 0, behavior = "smooth") => {
  window.scrollTo({
    top: top,
    behavior: behavior,
  });
};

export const applyClassToBody = (str) => {
  const flag = document.body.classList.contains(str);
  if (!flag) {
    document.body.className += (" " + str);
  }
};

export const addOrRemoveClass = (id, classname) => {
  const elements = document.getElementsByName(id);
  if (elements.length > 0) {
    elements.forEach((element) => {
      element.classList.toggle(classname);
    });
  }
};
export const addOrRemoveClassById = (id, classname) => {
  const elements = document.getElementById(id);
  if (elements) {
    elements.classList.toggle(classname);
  }
};

export const checkPageWidthGT = (width = 560) => {
  const vw = document.documentElement.clientWidth;
  if (vw > width) {
    return true;
  }
  return false;
};

export const getUrlQueryParam = (query = "") => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let res = params.get(query);
  return res;
};
