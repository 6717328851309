import React from 'react';
import Footer from './footer';
import Header from './header';

const Layout = (props) => {

  return (
    <>
      <div className="page">
        <Header />
        <div className="page-wrapper">
          {props.component}
          <Footer />
        </div>
      </div>
    </>
  );
}
export default Layout;
