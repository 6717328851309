import React, { useEffect, useState } from "react";
import { get_lang } from "../../../../common/utils/app.options";
import { sendRequest } from "../../../../common/api/apiClient";
import moment from "moment";
import IptvModel from "../../../../common/components/model/iptv_model";
import SendCredentials from "../../../../modules/management/dashboard/components/sentCredentials";
import ComingSoon from "../components/coming_soon";
import config from "../../../../config";

const formatDate = (date) => (date ? moment(date).format("YYYY-MM-DD") : "");
const parseDate = (date) => Date.parse(new Date(date));

const setStatus = (activation, expiration) => {
  const today = moment();

  const activation_date = activation ? moment(activation) : null;
  const expiration_date = activation ? moment(expiration) : null;
  if (expiration_date && activation_date) {
    const isActive =
      (activation_date.isBefore(today) && expiration_date.isAfter(today)) ||
      activation_date.isSame(today, "day");

    // return "active";
    if (isActive) return "active";
    if (activation_date.isAfter(today)) return "pending";
    if (expiration_date.isBefore(today)) return "expired";
    else {
      return "ERR";
    }
  } else {
    return "pending";
  }
};

const providers = {
  telecaststreaming: config.TELECAST_URL,
  vistanaiptv: config.VISTANA_URL,
};
const colors = {
  expired: "table-secondary",
  active: "table-success",
  pending: "",
};
function PlanDetailsForm({ obj }) {
  const [sortedData, setSortedData] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [showRequestCredentials, setShowRequestCredentials] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [data, setData] = useState([]);
  const URL = `subscriptions/${obj.parent_plan_identifier}`;
  useEffect(() => {
    sendRequest("GET", URL).then((res) => setData(res.data));
  }, [URL]);

  useEffect(() => {
    setSortedData(
      data.sort((a, b) => parseDate(b.created_at) - parseDate(a.created_at))
    );
  }, [data]);

  const getRedirectURL = () => {
    const tokenData = localStorage.getItem("palco-prepago-auth-user");
    if (activePlan && tokenData) {
      const { pin } = activePlan;
      const providerURL = providers[obj.provider_url];
      const token = JSON.parse(tokenData).access;
      const redirectURL = `${providerURL}/dashboard/${pin}?token=${token}`;
      return redirectURL;
    }
  };

  const handleAnchor = (event) => {
    if (!activePlan || obj.provider_url === "vistanaiptv")
      event.preventDefault();
  };
  return (
    <div className="row text-center">
      <div className="col-md-4 d-none d-md-block">
        <div className="text-center mb-4">
          <a role="button" className="navbar-brand navbar-brand-autodark">
            <img
              className="rounded "
              src={
                obj.plan_image
                  ? obj.plan_image
                  : "https://cdn.wccftech.com/wp-content/uploads/2020/01/Disney.jpg"
              }
              style={{
                height: "auto",
                width: "fit-content",
              }}
              alt=""
            />
          </a>
        </div>
      </div>
      <div className="col-md-8">
        <div className="d-flex  justify-content-around mt-3">
          <h3>{get_lang("service")} : </h3>
          <span>{obj.parent_plan}</span>
        </div>
        <div className="row">
          <div className="mb-3 mt-2 col-md-12">
            <button
              type="button"
              className={`btn btn-primary w-100 ms-auto ${
                !activePlan && "disabled"
              }`}
              data-bs-dismiss="modal"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Próximamente"
              onClick={() => setShowRequestCredentials(true)}
            >
              {get_lang("request_credentials")}
            </button>
          </div>
          {/*  <div className="mb-3 mt-2 col-md-6">
            <a
              href={getRedirectURL()}
              target="_blank"
              rel="noreferrer"
              onClick={handleAnchor}
            >
              <button
                type="button"
                className={`btn btn-primary w-100 ms-auto ${
                  (!activePlan || obj.provider_url === "vistanaiptv") &&
                  "disabled"
                }`}
                data-bs-dismiss="modal"
                data-toggle="tooltip"
                data-placement="bottom"
              >
                {get_lang("view_account")}
              </button>
            </a>
          </div>  */}
        </div>
      </div>

      <div className="col-md-12 mt-3">
        <div className="mb-3">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>{get_lang("pin")}</th>
                  <th>{get_lang("start_date")}</th>
                  <th className="px-4">{get_lang("expires")}</th>
                  {/* <th>{get_lang("period")}</th> */}
                  <th>{get_lang("status")}</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, i) => {
                  const status = setStatus(item.start_date, item.end_date);
                  const color = colors[status] || "";
                  if (status === "active" && !activePlan) setActivePlan(item);
                  return (
                    <tr key={i} className={color}>
                      <td>{item.pin}</td>
                      <td>{formatDate(item.start_date)}</td>
                      <td
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title={moment(item.end_date).format(
                          "YYYY-MM-DD, h:mm a"
                        )}
                        role="button"
                      >
                        {formatDate(item.end_date)}
                      </td>
                      {/* <td>TODO</td> */}
                      <td>{get_lang(status)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <IptvModel
        show={showRequestCredentials}
        showSubmitButton={false}
        handleClose={() => setShowRequestCredentials(false)}
        component={
          <SendCredentials
            parent_plan={obj.parent_plan}
            pin={activePlan && activePlan.pin}
            handleClose={() => setShowRequestCredentials(false)}
          />
        }
        title={get_lang("request_credentials")}
        modalSize={"modal-md"}
      />
      <IptvModel
        show={showComingSoon}
        showSubmitButton={false}
        handleClose={() => setShowComingSoon(false)}
        component={ComingSoon}
        title={get_lang("coming_soon")}
        modalSize={"modal-md"}
      />
    </div>
  );
}

export default PlanDetailsForm;
