import React, { useEffect } from 'react';
import { get_lang } from '../../utils/app.options';
import { applyClassToBody } from '../../utils/app.util';
// Then inside the component body

function IptvModel({ show, handleClose, component, title, form_id, button_ok_text = get_lang("submit"), button_close_text = '', showSubmitButton = true, modalSize = "modal-sm" }) {

    useEffect(() => {
        if (show) {
            applyClassToBody('modal-open');
        }
    }, []);

    return (
        <>
            {show &&
                <div className={`modal-backdrop  ${show ? 'show' : ''}`}></div>}
            <div className={`modal modal-blur  fade ${show ? 'show' : 'hide'}`} tabIndex={-1} role="dialog" aria-hidden="true" style={{ display: `${show ? 'block' : 'none'}` }} >
                <div className={`modal-dialog ${modalSize}`} role="document">

                    <div className={`modal-content modal-margin-custom`}>

                        <div className="modal-header">
                            <h5 className="modal-title">
                                {title}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label={get_lang("close")} onClick={handleClose} />
                        </div>
                        <div className="modal-body">
                            {component}
                        </div>
                        <div className="modal-footer">
                            {button_close_text !== '' &&
                                <button type="button" onClick={handleClose} className="btn me-auto" data-bs-dismiss="modal">{button_close_text}</button>
                            }
                            {showSubmitButton &&
                                <button type="submit" content='Submit' value='Submit' form={form_id} id="form-submit" className="btn btn-primary ms-auto" data-bs-dismiss="modal">
                                    {button_ok_text}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IptvModel;