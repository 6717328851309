import React from "react";
import { get_lang } from "../../../../common/utils/app.options";

function ComingSoon() {
  return (
    <div className="row text-center">
      <h2>
        {get_lang("coming_soon")}
        <span className="animated-dots"></span>
      </h2>

      {/* <div className="col-md-8">
        <div className="mb-3 mt-4">
          <a
            role="button"
            className="btn btn-primary w-100 ms-auto"
            data-bs-dismiss="modal"
          >
            Submit
          </a>
        </div>
        <div className="mb-3">
          <p className="text-muted">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Aperiam deleniti fugit incidunt, iste, itaque minima neque.
          </p>
        </div>
      </div>

      <div className="col-md-4" style={{background: "grey"}}>
        <div className="text-center mb-4">
          <a role="button" className="navbar-brand navbar-brand-autodark">
            <img src="./assets/img/netflix.png" height="100" alt=""/>
            </a>
        </div>
      </div> */}
    </div>
  );
}

export default ComingSoon();
